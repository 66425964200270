/**
 * Initialise the vue router to switch between the sites
 * @author Christopher Grätz
 * @version 1.0 / 2020-11-27
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { i18n } from '../plugins/i18n.js'
import Home from '@/views/Home.vue'
import searchRecommendations from '@/views/SearchRecommendations'
import BookDetail from '@/views/BookDetail.vue'
import BookDetailPrint from '@/views/BookDetailPrint.vue'
import findEBooks from '@/views/FindEBooks.vue'
import searchForEBooks from '@/views/SearchForEBooks.vue'
import contact from '@/views/Contact.vue'
import help from '@/views/Help.vue'
import settings from '@/views/Settings.vue'
import notFound from '@/views/NotFound.vue'
import allRecommendations from '@/views/AllRecommendations.vue'
import BookView from '@/views/BookView.vue'
import privacy from '@/views/Privacy.vue'
import imprint from '@/views/Imprint.vue'

Vue.use(VueRouter)

// Declare all possible routes in the web-app
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: imprint
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/searchRecommendations',
    name: 'searchRecommendations',
    component: searchRecommendations
  },
  {
    path: '/allRecommendations',
    name: 'allRecommendations',
    component: allRecommendations
  },
  {
    path: '/findEBooks',
    name: 'findEBooks',
    component: findEBooks
  },
  {
    path: '/SearchForEBooks',
    name: 'searchForEBooks',
    component: searchForEBooks
  },
  {
    path: '/book',
    name: 'bookDetail',
    component: BookDetail
  },
  {
    path: '/bookPrint',
    name: 'bookDetailPrint',
    component: BookDetailPrint
  },
  {
    path: '/bookview',
    name: 'bookView',
    component: BookView
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings
  },
  {
    path: '/help',
    name: 'help',
    component: help
  },
  {
    path: '*',
    component: notFound
  }
]

// Create a new router
const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Router.beforeEach((to, from, next) => {
  if (store.state.language.language && store.state.language.language !== i18n.locale) {
    i18n.locale = store.state.language.language
    next()
  } else if (!store.state.language.language) {
    store.dispatch('language/setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.state.language.language
        next()
      })
  } else {
    next()
  }
})

export default Router
