import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import language from './modules/language'
import books from './modules/books'
import login from './modules/login'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    language,
    books,
    login
  }
})

export const BACKEND_URL = 'https://service.bib.th-wildau.de/api/ebook2go' // http://icampusdev.th-wildau.de:5003 or http://localhost:5003

export default store
