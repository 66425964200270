<!--
   Header component - includes two navigations. One for the title and some useful links and settings.
   And an other navigation to navigate between the functions of the app
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <v-container>
    <!-- Left navigation between the sites of the app -->
    <v-navigation-drawer v-model="drawer" :permanent="!isMobile && drawer" :right="right" :touchless="true"
      :mini-variant="false" dark color="primary" app enable-resize-watcher class="side_navigation" width="300">
      <!-- Set a background image -->
      <v-img src="@/assets/pepper_bib.jpg" height="100%"
        gradient="to bottom right, rgba(0,149,236,0.9), rgba(0,62,99,.9)">
      <!-- Print all links to the different functions of the app and the login area -->
          <v-list dense nav class="py-0">
            <v-list-item two-line :class="'px-0'">
              <v-list-item-avatar>
                <img src="../assets/pepper_bib.jpg">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-group v-if="logStatus">
                  <v-list-item-title>{{ username }}</v-list-item-title>
                  <v-list-item-subtitle>{{ type }}</v-list-item-subtitle>
                </v-list-item-group>
                <v-spacer></v-spacer>
                <v-list-item-group>
                  <v-btn v-if="logStatus" @click="logout" class="secondary-button" small>
                    <v-icon>mdi-logout</v-icon>
                    {{ $t('btn_logout') }}
                  </v-btn>
                  <div v-else>
                    <!-- <LoginForm />  -->
                  </div>
                </v-list-item-group>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!-- Create the buttons for each app function -->
            <v-list-item v-for="item in items" :key="item.title" :to="{ name: item.link }" :value="item.active"
              class="nav_hover" :exact="item.exact">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-list-item class="bottom">
              <v-list-item-icon>
                <v-img src="../../public/logo.png" height="120%" style="filter: invert(1)"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                iCampus Wildau | &copy; 2021 | Christopher Grätz & Omar Attia (& Dana Geier)
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-img>
    </v-navigation-drawer>

    <!-- Upper / Header navigation between the sites of the app -->
    <v-app-bar dark color="primary" shrink-on-scroll fade-img-on-scroll app src="../assets/th_wildau.jpg">
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to bottom, rgba(0,149,236,0.7), rgba(0,62,99,.7)"></v-img>
      </template>

      <!-- Minimize title by very small devices -->
      <v-toolbar-title class="pa-0" v-if="mobile == 1">
        <h2>BibShelf+</h2>
      </v-toolbar-title>
      <v-toolbar-title class="pa-0" v-else-if="mobile == 0">
        <h2>BibShelf +</h2>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-row class="pa-0" align="center" style="flex: unset">
        <v-btn :to="{ name: 'imprint' }" class="no_btn pa-1" elevation="0">
          <a class="header underline">{{ $t("imprint") }}</a>
        </v-btn>
        <v-btn :to="{ name: 'privacy' }" class="no_btn pa-1" elevation="0">
          <a class="header underline"> {{ $t("privacy") }} </a>
        </v-btn>

        <v-btn class="pa-2" icon small fab :title="$t('languagePicker')">
          <LanguagePicker />
        </v-btn>

        <v-btn class="pa-2" v-if="!settings" icon small fab :to="{ name: 'settings' }" :title="$t('siteSettings')"
          @click="settings = true">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn v-else class="pa-2" icon small fab :to="{ name: 'home' }" :title="$t('siteSettings')"
          @click="settings = false">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
  </v-container>
</template>

<script>
import LanguagePicker from '@/components/LanguagePicker'
// import LoginForm from '@/components/LoginForm'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { LanguagePicker },
  data () {
    return {
      drawer: true,
      right: false, // set the position of the left nav
      expandOnHover: true, // expand the left navigation on mouse hover
      background: true,
      settings: false, // is the settings site actual visible
      isMobile: false
    }
  },
  methods: {
    ...mapActions(['login', 'logout']),
    onResize () {
      this.isMobile = window.innerWidth < 600
    }
  },
  computed: {
    ...mapGetters(['logStatus', 'username', 'type']),
    /**
     * Get all app functions for the left menu with related icons and a link to the function (vue router)
     * If you are logged in you can see more functions
     */
    items: function () {
      var itemList = [
        { title: 'home', icon: 'mdi-home-outline', link: 'home' },
        { title: 'find', icon: 'mdi-book-search', link: 'searchForEBooks' },
        { title: 'search', icon: 'mdi-magnify', link: 'findEBooks' },
        { title: 'help', icon: 'mdi-help-box', link: 'help' },
        { title: 'contact', icon: 'mdi-account-box-outline', link: 'contact' }
      ]
      // if a student is logged in add other functions to the list and therewith to the navigation
      if (this.type === 'student') {
        var allRecommendations = { title: 'allRecommendations', icon: 'mdi-account-circle-outline', link: 'allRecommendations' }
        itemList.splice(1, 0, allRecommendations)
        var searchRecommendations = { title: 'searchRecommendations', icon: 'mdi-feature-search', link: 'searchRecommendations' }
        itemList.splice(2, 0, searchRecommendations)
      } else if (this.type === '') {
        // console.log('no')
      } else {
        var searchRecommendations1 = { title: 'searchRecommendations', icon: 'mdi-feature-search', link: 'searchRecommendations' }
        itemList.splice(1, 0, searchRecommendations1)
      }
      return itemList
    },
    /**
     * Get the information if the grid is extra small
     * @returns true- xs is on, false - breakpoint xs is not active
     */
    mobile () {
      return this.$vuetify.breakpoint.xs
    }
  },
  mounted () {
    this.$store.dispatch('fetchImprintAndDP')
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  }
}
</script>
