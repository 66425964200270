/**
 * A class for manage the e-book covers
 * @author Omar Attia
 * @version 1.0 / 2020-11-27
 */
import { BACKEND_URL } from '@/store/index.js'

let axios = require('axios')

/**
 * Get a book cover
 * @param isbn
 * @param updateF
 * @returns {Promise<void>}
 */
async function getBookCover (isbn, updateF) {
  getAmazon(isbn, updateF) // default cover supplier
}

/**
 * Get a book cover with the api from Amazon
 * @param isbn commit a isbn to the api to get a cover
 * @param updateF
 */
function getAmazon (isbn, updateF) {
  let search = isbn
  // If ISBN13, transform it into ISBN10
  search = search.replace(/-/g, '')
  if (search.length === 13) {
    search = search.substr(3, 9)
    // Calculate checksum
    let checksum = search.split('').reduce(function (s, c, i) {
      return s + parseInt(c) * (i + 1)
    }, 0)
    checksum %= 11
    checksum = checksum === 10 ? 'X' : checksum
    search += checksum
  }
  let url = 'https://images-na.ssl-images-amazon.com/images/P/' + search + '.01.MZZZZZZZZZ.jpg'
  getMeta(url, updateF, isbn)
}

/**
 * Get a book cover with the api from Google. This thing is outsourced in the backend
 * @param isbn commit a isbn to the api to get a cover
 * @param updateF
 * @returns {Promise<void>}
 */
async function getGoogle (isbn, updateF) {
  let url = BACKEND_URL + '/searchCover/' + isbn
  await axios.get(url, { timeout: 5000 }).then(function (res) {
    if (res.data === undefined) {
      url = 'false'
    } else {
      // console.log(res.data)
      updateF(res.data)
    }
  }).catch(function () {
    // console.log('timeout')
    return false
  })
}

function getMeta (url, sendToUI, isbn) {
  let img = new Image()
  img.onload = function () {
    // console.log(url + ': ' + this.width)
    // get book cover from different api when picture is to small and the book has an isbn
    if (this.width < 100) {
      url = 'false'
      if (isbn) {
        getGoogle(isbn, sendToUI)
      }
    }
    sendToUI(url)
  }
  img.src = url
}

export default { getBookCover }
