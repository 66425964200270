<!--
   The basic skeleton of the web-app.
   On every site print the header component and the main area, which are the different functions of the app
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <v-app>
    <Header/>
    <v-content>
      <router-view />
    </v-content>
<!--    <Footer/>-->
  </v-app>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'app',
  components: {
    Header
  }
}
</script>
