<!--
   FindEbook site - to find an e-book with search parameters
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
      <v-row>
        <!-- Input a search string -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <label>{{ $t('selectTheme') }}</label>
          <v-text-field
                  v-model="selectedTheme"
                  id="thema"
                  item-text="message"
                  dense
                  clearable
                  outlined
                  :placeholder="$t('placeholder_theme')"
                  v-on:keyup.enter="updateFilter"
          >
          </v-text-field>
        </v-col>

        <!-- Select a sort criteria -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <label>{{ $t('selectSort') }}</label>
          <v-select
                  v-model="selectedSortCrit"
                  class="select"
                  id="show_after_sorting"
                  :items="sorting"
                  item-text="message"
                  outlined
                  dense
                  :placeholder="$t('placeholder_sortCrit')"
                  clearable
          >
          </v-select>
        </v-col>

        <!-- Select the publishing time -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <label>{{ $t('time') }}</label>
          <v-select
                  v-model="selectedDate"
                  class="select"
                  :items="times"
                  item-text="message"
                  outlined
                  dense
                  clearable
                  :placeholder="$t('placeholder_date')"
          >
          </v-select>
        </v-col>

        <!-- Select a preferred language of the e-book -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <label>{{ $t('language') }}:</label>
          <v-select
                  v-model="selectedLanguage"
                  :items="language"
                  class="select"
                  outlined
                  dense
                  :placeholder="$t('placeholder_language')"
                  clearable
          >
          </v-select>
        </v-col>

        <!-- Select the number of results -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <label>{{ $t('numResults') }}:</label>
          <v-select
                  v-model="selectedHit"
                  :items="counter"
                  class="select"
                  outlined
                  dense
                  @input="updateFilter"
          >
          </v-select>
        </v-col>

        <!-- Start the searching -->
        <v-col cols="6" class="col-lg-2 col-md-4 col-sm-6 col-xs-6 pa-1">
          <v-btn class="secondary-button center-vertically" @click="updateFilter">
            <v-icon>mdi-magnify</v-icon>
            {{ $t('searchBooks') }}
          </v-btn>
        </v-col>
      </v-row>
      <BookGrid />
    </v-container>
</template>

<script>
import BookGrid from '@/components/BookGrid'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'home',
  components: { BookGrid },
  data () {
    return {
      isFindParent: true, // to give the BookGrid a hint, which books from the store should be displayed
      selectedHit: 20,
      selectedTheme: '',
      selectedSortCrit: '',
      selectedDate: '',
      selectedLanguage: '',
      sorting: [
        { message: 'Autor aufsteigend', value: 1 },
        { message: 'Autor absteigend', value: 2 },
        { message: 'Relevanz', value: 3 },
        { message: 'Erscheinungszeitraum aufsteigend', value: 4 },
        { message: 'Erscheinungszeitraum absteigend', value: 5 },
        { message: 'Titel aufsteigend', value: 6 },
        { message: 'Titel absteigend', value: 7 }
      ],
      counter: [ 10, 20, 50, 100 ],
      language: [ 'Deutsch', 'Englisch' ],
      times: [
        { message: '2020-2022' },
        { message: '2015-2019' },
        { message: '2010-2014' },
        { message: '2005-2009' },
        { message: '2000-2004' },
        { message: 'No' }
      ]
    }
  },
  methods: {
    ...mapActions(['searchBooks', 'setHitsPerPage', 'setDate', 'setSorting', 'setTheme', 'setLanguage']),
    /**
     * Start the vue store action to get e-books from the backend
     * @param e
     * @returns {Promise<void>}
     */
    async updateFilter (e) {
      // console.log(e)
      /*
      this.setHitsPerPage(e)
      this.setSorting()
      this.setDate()
      this.setTheme()
      this.setLanguage()
      */
      // console.log('erneuter Aufruf von updateFilter')
      var clientData = { 'theme': this.selectedTheme, 'sort': this.selectedSortCrit, 'published': this.selectedDate, 'hits': this.selectedHit, 'language': this.selectedLanguage }
      this.searchBooks(clientData)
    }
  },
  computed: {
    ...mapGetters(['searchedBooks', 'shelf'])
  },
  created () {
    this.setHitsPerPage(this.selected)
  }
}
</script>
