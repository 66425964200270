<!--
   Search recommendations site - to get recommendations for all study programmes
   You can filter by semester and course
   @author Christopher Grätz, Omar Attia
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
      <v-row>
        <v-col cols md="3" sm="13">
          <!-- Select a study programme -->
          <label>{{ $t('studiengang') }}:</label>
          <v-select
                  v-model="selectedMajor"
                  :items="majors"
                  item-text="major"
                  class="select"
                  outlined
                  dense
                  :placeholder="$t('placeholder_studiengang')"
                  clearable
                  @input="getSemNumber"
          >
          </v-select>
          <v-btn class="secondary-button" @click="updateFilter">
            <v-icon>mdi-magnify</v-icon>
            {{ $t('searchRecommendations') }}
          </v-btn>
        </v-col>

        <!-- Select a semester -->
        <v-col cols md="3" sm="13">
          <label>{{ $t('semester') }}</label>
          <v-select
                  v-model="selectedSemester"
                  class="select"
                  :items="semesterCount"
                  outlined
                  dense
                  :placeholder="$t('placeholder_semester')"
                  clearable
                  @input="getModules"
          >
          </v-select>
        </v-col>

        <!-- Select a module -->
        <v-col cols md="3" sm="13">
          <label>{{ $t('modul') }}</label>
          <v-select
                  v-model="selectedModule"
                  class="select"
                  :items="modules"
                  item-text="title"
                  outlined
                  dense
                  :placeholder="$t('placeholder_modul')"
                  clearable
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- Load the recommendations and present them in a book slider for each module  -->
        <div v-if="recommendationsLoading && !searchRecommendations.length">
          <div class="pa-16 text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
          </div>
        </div>
        <div v-if="!this.selectedModule">
          <v-container v-for="(module, i) in searchRecommendations" :key="i">
            <div v-if="module.recBooks.length">
              <h2 class="left_border">{{ module.title }}</h2>
              <BookSlider :toDisplay="module.recBooks"/>
            </div>
          </v-container>
        </div>
        <div v-else-if="this.foundModule">
          <div v-if="this.foundModule.recBooks.length">
              <h2 class="left_border">{{ this.foundModule.title }}</h2>
              <BookSlider :toDisplay="this.foundModule.recBooks"/>
          </div>
          <div v-else>
            <Hint :text="$t('noRecommendations')" :color="'red'" :background="'text'" :icon="'error'"></Hint>
          </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rsengine from '@/rsengine.js'
import BookSlider from '@/components/BookSlider'
import Hint from '@/components/Hint'

export default {
  name: 'SearchRecommendations',
  components: { Hint, BookSlider },
  computed: {
    ...mapGetters(['studiengang', 'seminargruppe', 'token', 'searchRecommendations', 'recommendationsLoading']),
    foundModule () {
      var foundModule
      if (this.searchRecommendations.length) {
        foundModule = this.searchRecommendations.find(x => x.title === this.selectedModule)
      }
      return foundModule
    }
  },
  data () {
    return {
      selectedMajor: null,
      selectedSemester: null,
      semesterCount: [],
      selectedModule: null,
      modules: [],
      majors: [ // all study programmes from TH Wildau
        { major: 'Automatisierte Energiesysteme', value: 'AEM' },
        { major: 'Aviation Management', value: 'AM' },
        { major: 'Automatisierungstechnik', value: 'AU' },
        { major: 'Betriebswirtschaft', value: 'B1' },
        { major: 'Business Administration', value: 'BAM' },
        { major: 'Business Administration (Digital Management)', value: 'BAM_Dig' },
        { major: 'Business Administrations (Energy and Infrastructure Management)', value: 'BAM_EM' },
        { major: 'Business Administrations (Project Management)', value: 'BAM_PM' },
        { major: 'Biosystemtechnik / Bioinformatik', value: 'BB' },
        { major: 'Betriebswirtschaft BS', value: 'BFG' },
        { major: 'Bibliotheksinformatik', value: 'BIM' },
        { major: 'Europäisches Management', value: 'EM1' },
        { major: 'Europäisches Management (Master)', value: 'EMM' },
        { major: 'Wirtschaftsinformatik', value: 'I1' },
        { major: 'Wirtschaftsinformatik (Master)', value: 'IM' },
        { major: 'Logistik', value: 'L1' },
        { major: 'Luftfahrttechnik / Luftfahrtlogistik', value: 'LL' },
        { major: 'Luftfahrttechnik / Luftfahrtlogistik (Master)', value: 'LLM' },
        { major: 'Logistics and Supply Chain Management (Master)', value: 'LSCM' },
        { major: 'Maschinenbau', value: 'M' },
        { major: 'Maschinenbau (Master)', value: 'MM' },
        { major: 'Physikalische Technologien / Energiesysteme', value: 'PE' },
        { major: 'Photonik (Master)', value: 'PM' },
        { major: 'Telematik', value: 'T' },
        { major: 'Telematik (Master)', value: 'TM' },
        { major: 'Technical Management (Master)', value: 'TMM' },
        { major: 'Verwaltungsinformatik Brandenburg', value: 'VIB1' },
        { major: 'Verkehrssystemtechnik', value: 'VS' },
        { major: 'Wirtschaftsingenieurwesen', value: 'W1' },
        { major: 'Wirtschaft und Recht', value: 'WR1' },
        { major: 'Wirtschaft und Recht (Master)', value: 'WRM' },
        { major: 'Öffentliche Verwaltung Brandenburg', value: 'ÖVB1' }
      ]
    }
  },
  methods: {
    ...mapActions(['getSearchRecommendations', 'setUser', 'getNumberOfSemesters']),
    /**
     * Get the modules list from the chosen study programme to add them to the dropdown list
     * @param e
     * @returns {Promise<void>}
     */
    async getModules (e) {
      if (this.selectedMajor && this.selectedSemester) {
        var list = []
        await rsengine.getModules(this.selectedSemester, this.selectedMajor, 19, this.token, function (modules) {
          list = modules
        })
        this.modules = list
      }
    },
    /**
     * Get the number of modules from the chosen study programme and semester to add them to the dropdown list
     * @param major
     * @returns {Promise<void>}
     */
    async getSemNumber (major) {
      var list = []
      await rsengine.getNumberOfSemesters(this.selectedMajor, 19, this.token, function (number) {
        for (var i = 1; i < number + 1; i++) {
          list.push(i)
        }
      })
      this.semesterCount = list
      this.selectedSemester = this.semesterCount[0]
      this.getModules()
    },
    /**
     * Update the result list to print it in the book sliders
     * @param e
     * @returns {Promise<void>}
     */
    async  updateFilter (e) {
      var requestOptions = {
        'studiengang': this.selectedMajor,
        'seminargruppe': 19,
        'token': this.token,
        'requestedSemester': this.selectedSemester
      }
      await this.getSearchRecommendations(requestOptions)
    }
  }
}
</script>
