<!--
   Settings site - to set some notification settings or e-mail settings
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
   TODO: integrate into UNIDOS Wildau
-->
<template>
    <v-container>
      <!-- <v-banner
                icon="mdi-bell"
                class="mt-4"
        >
            {{ $t('set_notifications') }}
        </v-banner>
        <v-container>
            <v-text-field
                v-model="email"
                counter
                counter-value="50"
                :label="$t('email')"
                outlined
                clear-icon="mdi-close-circle"
                clearable
                placeholder="christopher.hgraetz@th-wildau.de"
                :append-outer-icon="email ? 'mdi-content-save-edit' : 'mdi-pencil'"
                @click:append-outer="saveEmail">
            </v-text-field>
            <v-switch
                    :label="$t('switch_push')"
                    color="warning"
                    value="On"
                    hide-details
                    ripple
            ></v-switch>
            <v-switch
                    :label="$t('switch_email')"
                    color="warning"
                    value="On"
                    hide-details
                    ripple
            ></v-switch>
        </v-container>-->
    </v-container>
</template>

<script>
export default {
  name: 'Settings',
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      select: ['Vuetify', 'Programming'],
      topics: [
        'Programming',
        'Design',
        'Vue',
        'Vuetify'
      ]
    }
  },
  methods: {
    saveFirstName () {
    },
    saveLastName () {
    },
    saveEmail () {
    }
  }
}
</script>
