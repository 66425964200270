/**
 * Initialise the vuetify extension and add it to the web-app
 * @author Christopher Grätz
 * @version 1.0 / 2020-11-27
 */
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import light from '@/plugins/vuetify/theme-ThWildau-light'

Vue.use(Vuetify)
Vue.use(VueI18n)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light
    }
  }
})
