<!--
   SearchForEBooks site - to get related e-books to a shelf from the TH Wildau library.
   Therefor scan a (qr)-code and display the result in a Book grid
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <div class="container">
    <Location />
    <h2 class="left_border">{{ $t('searchResults') }}</h2>
    <div class="pa-16 text-center">
    <BookGrid />
    </div>
    <h2 class="left_border">{{ $t('lentBooks') }}</h2>
    <div class="pa-16 text-center">
      <BookGridPrint />
    </div>
  </div>
</template>

<script>

import BookGrid from '@/components/BookGrid'
import Location from '@/components/Location'
import BookGridPrint from '@/components/BookGridPrint'

export default {
  name: 'search',
  components: { Location, BookGrid, BookGridPrint }
}
</script>
