<!--
   NotFound site - to display a (404) site, when a link to the web app has changed or is not more reachable
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
        <v-row>
            <v-col cols md="5" sm="8">
                <img src="@/assets/pepper.png" class="notfound_pic">
            </v-col>
            <v-col md="7" sm="12">
                <h1 class="notfound_status">404</h1>
                <h2 class="notfound_header">{{ $t('notFound_headline')}}</h2>
                <p class="notfound_text">
                    {{ $t('notFound_text')}}
                    <router-link class="underline" to="/">homepage</router-link>
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
