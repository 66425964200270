<!--
   Book grid component - show all founded books in a grid with responsive design
   @author Dana Geier
   @version 1.0 / 2021-06-12
--->
<template>
    <div>
      <v-container class="pa-0">
        <!-- If no books are found show a hint -->
        <Hint v-if="this.$store.getters.lentPrintBooks === null" :text="$t('noResultLentBooks')" :color="'red'" :background="'text'" :icon="'error'">"$t('noResultLentBooks')"</Hint>
        <!-- Create cards for each book in list with a title and a book cover -->
        <v-row v-if="this.$store.getters.lentPrintBooks !== null">
          <v-card
              v-for="(book, i) in bookList" :key="book.barcode"
              class="mx-3 mb-5"
              width="250"
              hover
              :ripple="{ class: 'primary--text' }"
              :to="{ name: 'bookDetailPrint', query: {bookList: bookList, index: i} }"
          >
            <v-hover v-slot:default="{ hover }">
              <v-img
                  href="/book"
                  :data-isbn="book.isbn.split(',')[0]"
                  :src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                  :lazy-src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                  height="350"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular :size="150" indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
                <v-expand-transition>
                  <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out secondary_gradient v-card--reveal white--text"
                      style="height: 38%;"
                  >
                    {{ $t('show')}}
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <!--
            <v-btn
                absolute
                class="btn_card_link secondary-button white--text"
                fab
                right
                ripple
                :title="$t('show')"
                @click="link(book.link, book.linkPda)"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
            -->
            <v-card-title class="text-wrap">
              {{ book.title.split(':')[0] }}
            </v-card-title>
          </v-card>
        </v-row>
      </v-container>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import Hint from '@/components/Hint'
export default {
  name: 'BookGridPrint',
  components: { Hint },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['lentPrintBooks']),
    bookList () {
      /*
      if (this.$parent.isFindParent) {
        return this.$store.getters.searchedBooks
      } else {
        return this.$store.getters.foundBooks
      } */
      return this.$store.getters.lentPrintBooks
    }
  },
  methods: {
    ...mapActions(['placeReservation']),

    /**
     * Send a reservation
     */
    async sendRes () {
      // console.log('call sendRes')
      // console.log(this.reservationItem)
      await this.placeReservation(this.reservationItem)
      // console.log(this.reservationResult)
      this.closeDialog()
    },

    // create a link to the publisher site from the given e-book
    link: function (link, linkPda) {
      // console.log(link, linkPda)
      if (link) {
        window.open('https://wilbert.kobv.de/' + link, '_blank')
      } else {
        window.open('https://wilbert.kobv.de/' + linkPda, '_blank')
      }
    }
  }
}
</script>
