/**
 * A vuex store component to handle the log-in process with the TH Wildau account
 * @author Omar Attia
 * @version 1.0 / 2020-11-27
 */

import axios from 'axios'
import rsengine from '@/rsengine.js'
import cengine from '@/cengine.js'
import { BACKEND_URL } from '@/store/index.js'

const state = {
  loggedIn: false,
  username: '',
  studiengang: '',
  seminargruppe: '',
  libraryID: '',
  email: '',
  type: '',
  token: '',
  timestamp: 0,
  recommendations: [],
  semRecommendations: [],
  searchRecommendations: [],
  numberOfSemesters: 0,
  recommendationsLoading: false,
  reservationResult: null,
  reservationState: 'confirmation'
}

const getters = {
  logStatus: (state) => state.loggedIn,
  username: (state) => state.username,
  type: (state) => state.type,
  studiengang: (state) => state.studiengang,
  seminargruppe: (state) => state.seminargruppe,
  libraryID: (state) => state.libraryID,
  email: (state) => state.email,
  token: (state) => state.token,
  timestamp: (state) => state.timestamp,
  recommendations: (state) => state.recommendations,
  semRecommendations: (state) => state.semRecommendations,
  searchRecommendations: (state) => state.searchRecommendations,
  numberOfSemesters: (state) => state.numberOfSemesters,
  recommendationsLoading: (state) => state.recommendationsLoading,
  reservationResult: (state) => state.reservationResult,
  reservationState: (state) => state.reservationState
}

const actions = {
  /**
   * Try to log in
   * @param commit
   * @param loginData
   * @returns {Promise<void>}
   */
  async login ({ commit }, loginData) {
    let postData = {
      user_id: loginData.username,
      pass: encodeURIComponent(loginData.password)
    }
    let loginRequest =
    'https://icampus.th-wildau.de/services.php?using_version=1.9&service=Login'
    // console.log(loginRequest)
    try {
      await axios.post(loginRequest, postData).then(function (response) {
        let responseData = response.data
        // console.log(responseData)
        if (responseData.response.error) {
          throw new Error()
        }
        // console.log(responseData.response)
        let _username = responseData.response.name
        let _type = responseData.response.type
        let _token = responseData.response.token
        let _studiengang = responseData.response.major
        let _seminargruppe = responseData.response.major_year
        let email = responseData.response.email
        let _libraryID = responseData.response.library_id
        // console.log('libraryID from request: ' + responseData.response.library_id)
        let _timestamp = responseData.request.timestamp
        commit('setStatus', true)
        commit('setUsername', _username)
        commit('setType', _type)
        commit('setToken', _token)
        commit('setStudienGang', _studiengang)
        commit('setSeminarGruppe', _seminargruppe)
        commit('setEmail', email)
        commit('setTimestamp', _timestamp)
        commit('setLibraryID', _libraryID)

        localStorage.setItem('user', JSON.stringify(responseData))
        return true
      })
    } catch (err) {
      throw new Error()
    }
  },
  /**
   * Log out from the web-app and reset the variables
   * @param commit
   * @returns {Promise<void>}
   */
  async logout ({ commit }) {
    commit('setStatus', false)
    commit('setUsername', '')
    commit('setType', '')
    commit('setToken', '')
    commit('setStudienGang', '')
    commit('setSeminarGruppe', '')
    commit('setLibraryID', '')
    commit('setEmail', '')
    commit('setTimestamp', '')
    commit('reservationResult', '')
    localStorage.removeItem('user')
  },
  /**
   * After log in get some data about the person and set some necessary variables
   * @param commit
   * @returns {Promise<void>}
   */
  async setUser ({ commit }) {
    let _user = JSON.parse(localStorage.getItem('user'))
    let _username = _user.response.name
    let _type = _user.response.type
    let _token = _user.response.token
    let _studiengang = _user.response.major
    let _seminargruppe = _user.response.major_year
    let _email = _user.response.email
    let _timestamp = _user.request.timestamp
    let _libraryID = _user.response.library_id
    // console.log(_user.response.library_id)
    commit('setStatus', true)
    commit('setType', _type)
    commit('setUsername', _username)
    commit('setToken', _token)
    commit('setStudienGang', _studiengang)
    commit('setSeminarGruppe', _seminargruppe)
    commit('setLibraryID', _libraryID)
    commit('setEmail', _email)
    commit('setTimestamp', _timestamp)
  },
  /**
   * Get recommendations for the users home site
   * @param commit
   * @returns {Promise<void>}
   */
  async getRecommendations ({ commit }) {
    commit('setRecommendationsLoading', true)
    await rsengine.generateRecommendations(state.studiengang, state.seminargruppe, state.token, function (recBooks) {
      for (let book of recBooks) {
        cengine.getBookCover(book.isbn.split(',')[0], function (cUrl) {
          if (cUrl === 'false') {
            book['cover'] = undefined
          } else {
            book['cover'] = cUrl
          }
        })
      }
      // console.log('Recommendations loaded...')
      commit('setRecommendations', recBooks)
      commit('setRecommendationsLoading', false)
    })
  },
  /**
   * Get recommendations for a given semester of the users study programme
   * @param commit
   * @param requestOptions
   * @returns {Promise<void>}
   */
  async getSemRecommendations ({ commit }, requestOptions) {
    commit('setSemRecommendations', [])
    commit('setRecommendationsLoading', true)
    await rsengine.generateForSem(requestOptions.studiengang, requestOptions.seminargruppe, requestOptions.token, requestOptions.requestedSemester, function (modules) {
      for (let module of modules) {
        for (let book of module['recBooks']) {
          cengine.getBookCover(book.isbn.split(',')[0], function (cUrl) {
            if (cUrl === 'false') {
              book['cover'] = undefined
            } else {
              book['cover'] = cUrl
            }
          })
        }
      }
      // console.log('Sem recommendations loaded...')
      // console.log(modules)
      commit('setSemRecommendations', modules)
      commit('setRecommendationsLoading', false)
    })
  },
  /**
   * Get recommendations for a chosen study programme --> semester --> module
   * @param commit
   * @param requestOptions
   * @returns {Promise<void>}
   */
  async getSearchRecommendations ({ commit }, requestOptions) {
    commit('setSearchRecommendations', [])
    commit('setRecommendationsLoading', true)
    await rsengine.generateForSem(requestOptions.studiengang, requestOptions.seminargruppe, requestOptions.token, requestOptions.requestedSemester, function (modules) {
      for (let module of modules) {
        for (let book of module['recBooks']) {
          cengine.getBookCover(book.isbn.split(',')[0], function (cUrl) {
            if (cUrl === 'false') {
              book['cover'] = undefined
            } else {
              book['cover'] = cUrl
            }
          })
        }
      }
      // console.log('Search recommendations loaded...')
      // console.log(modules)
      commit('setSearchRecommendations', modules)
      commit('setRecommendationsLoading', false)
    })
  },
  /**
   * Get the standard number of semesters of a study programme
   * @param commit
   * @returns {Promise<void>}
   */
  async getNumberOfSemesters ({ commit }) {
    await rsengine.getNumberOfSemesters(state.studiengang, state.seminargruppe, state.token, function (numberOfSemesters) {
      commit('setNumberOfSemesters', numberOfSemesters)
    })
  },
  /**
   * Send a recommendation to a friend
   * @param commit
   * @param requestOptions
   * @returns {Promise<void>}
   */
  async sendRecommendation ({ commit }, requestOptions) {
    let url = BACKEND_URL + '/sendBook/' + requestOptions.from + '/' + requestOptions.to + '?' + requestOptions.bookLink
    await axios.get(url).then(function (response) {
      // console.log(response)
    })
  },
  /**
   * Place a reservation
   * @param commit
   * @param reservationItem
   * @returns {Promise<void>}
   */
  async placeReservation ({ commit }, reservationItem) {
    let url = BACKEND_URL + '/placeReservation/' + state.libraryID + '/' + reservationItem.barcode
    // console.log(url)
    commit('setReservationState', 'loading')
    await axios.get(url).then(function (response) {
      // console.log(response)
      // console.log(response.data)
      // console.log(response.data.screenMessage[2])
      // console.log(response.data.screenMessage[3])
      commit('setReservationResult', response.data)
      if (response.data.ok === true) {
        commit('setReservationState', 'success')
      } else {
        commit('setReservationState', 'failed')
      }
      if (response.data === 'API_ERROR') {
        commit('setReservationState', 'failedAPI')
      }
    }).catch((error) => {
      console.log('upps, something went wrong while placing a reservation')
      console.log(error.message)
      commit('setReservationState', 'failedAPI')
    })
  },

  async setReservationState ({ commit }, reservationState) {
    commit('setReservationState', reservationState)
  }
}

const mutations = {
  setStatus: (state, status) => (state.loggedIn = status),
  setType: (state, type) => (state.type = type),
  setUsername: (state, username) => (state.username = username),
  setStudienGang: (state, studiengang) => (state.studiengang = studiengang),
  setSeminarGruppe: (state, seminargruppe) => (state.seminargruppe = seminargruppe),
  setToken: (state, token) => (state.token = token),
  setRecommendations: (state, recommendations) => (state.recommendations = recommendations),
  setSemRecommendations: (state, semRecommendations) => (state.semRecommendations = semRecommendations),
  setSearchRecommendations: (state, searchRecommendations) => (state.searchRecommendations = searchRecommendations),
  setNumberOfSemesters: (state, numberOfSemesters) => (state.numberOfSemesters = numberOfSemesters),
  setTimestamp: (state, timestamp) => (state.timestamp = timestamp),
  setRecommendationsLoading: (state, status) => (state.recommendationsLoading = status),
  setEmail: (state, email) => (state.email = email),
  setLibraryID: (state, libraryID) => (state.libraryID = libraryID),
  setReservationResult: (state, reservationResult) => (state.reservationResult = reservationResult),
  setReservationState: (state, reservationState) => (state.reservationState = reservationState)
}

export default {
  state,
  getters,
  actions,
  mutations
}
