<!--
   Book detail site - to get further information of an e-book
   @author Christopher Grätz, Omar Attia
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
        <v-card flat>
        <v-row>
            <v-col>
                <!-- Go back to the book grid or book slider -->
                <v-btn class="secondary-button"
                @click="$router.go(-1)">
                    {{ $t('back') }}
                    <v-icon>mdi-view-list</v-icon>
                </v-btn>
            </v-col>
            <v-col class="align-right">
                <!-- Get the previous e-book in list -->
                <v-btn outlined class="primary-button"
                @click="getPrevBook">
                    <v-icon>mdi-arrow-left</v-icon>
                    {{ $t('previousBook') }}
                </v-btn>
                <!-- Get the next e-book in list -->
                <v-btn outlined class="secondary-button"
                @click="getNextBook">
                    {{ $t('nextBook') }}
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        </v-card>

        <!-- Print all necessary things for the e-book: a cover, titles, year, isbn and so on -->
        <v-card flat>
            <v-row>
            <v-col lg="6" md="3" sm="12" xs="12">
                <v-img
                :src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                :lazy-src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                contain
                max-height="500px"
                >
                </v-img>
                <template v-slot:placeholder>
                <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                >
                  <v-progress-circular :size="150" indeterminate color="primary"></v-progress-circular>
                </v-row>
                </template>
            </v-col>
            <v-col lg="6" md="9" sm="12" xs="12" class="">
                <h2>{{ book.title.split(':')[0] }}</h2>
                <h3>{{ book.title.split(':')[1] }}</h3>
                <h3>({{ book.year}})</h3><br>
                <h5>{{ book.publisher }}</h5>
                <h5>{{ book.author }}</h5>
                <!-- <h5>{{ book.language }}</h5> -->
                <h5> ISBN: {{ book.isbn }}</h5>
                <!--
                <v-rating v-model="rating" color="var(--v-secondary-base)" hover ripple clearable class="mt-5"></v-rating>
                <v-btn fab small dark hover color="error" class="mt-5">
                    <v-icon>mdi-plus</v-icon>
                </v-btn><br>
                -->
                <v-col></v-col>
                <!-- A link to the publishers site -->
                <v-btn v-if="!this.$store.getters.fulltextLoading" class="secondary-button mt-5" @click="link(book.url_ebook, book.doi)">
                    <v-icon>mdi-link</v-icon>
                    {{ $t('show') }}
                </v-btn>
                    <v-progress-circular v-if="this.$store.getters.fulltextLoading"
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                <!-- Recommend an e-book to a friend -->
                <!-- Fill in an e-mail to recommend an e-book to a friend -->
                <EMailOverlay :sendBook="this.book"/>

            </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EMailOverlay from '@/components/EMailOverlay'
import axios from 'axios'
import { BACKEND_URL } from '@/store/index.js'
export default {
  name: 'book',
  components: { EMailOverlay },
  data () {
    return {
      rating: 0,
      bookList: [],
      book: {},
      index: 0,
      nextBook: {},
      previousBook: {}
    }
  },
  computed: {
    ...mapGetters(['fulltextLoading'])
  },
  created () {
    this.bookList = this.$route.query.bookList
    this.index = this.$route.query.index
    this.book = this.bookList[this.index]
    if (this.$store.getters.fulltextLoading) {
      setTimeout(() => {
        this.setFulltextLoading(false)
      }, 3000)
    }
  },
  methods: {
    ...mapActions(['setFulltextLoading']),
    getNextBook () {
      if (this.index >= 0 && this.index < this.bookList.length - 1) {
        this.nextBook = this.bookList[this.index + 1]
        this.index++
        this.book = this.nextBook
      }
    },
    getPrevBook () {
      if (this.index > 0) {
        this.previousBook = this.bookList[this.index - 1]
        this.index--
        this.book = this.previousBook
      }
    }, /*
    linkEBook: function (url, doi) {
      this.link(url, doi)
    } */
    link: function (url, doi) {
      this.setFulltextLoading(true)
      // console.log('URL: ' + url)
      // console.log('DOI:' + doi)
      // console.log('decoded URI: ' + encodeURIComponent(doi))
      let fulltextUrl = BACKEND_URL + '/fulltext/' + encodeURIComponent(doi)
      // console.log(fulltextUrl)
      if (doi !== '' && (doi.startsWith('10.1524') || doi.startsWith('10.1515') || doi.startsWith('10.1007'))) {
        axios.get(fulltextUrl, {
          proxy: {
            host: 'proxy.th-wildau.de',
            port: 8080
          }
        })
          .then(response => {
            // console.log(response.data)
            if (response.data === 'useStandardUrl') {
              window.open(url, '_blank')
            } else {
              // alert(response.data)
              window.open(decodeURIComponent(response.data))
            }
          })
          .catch(error => {
            console.log(error.message)
            window.open(url, '_blank')
          })
      } else {
        window.open(url, '_blank')
      }
      setTimeout(() => {
        this.setFulltextLoading(false)
      }, 3000)
      // this.setFulltextLoading(false)
    }
  }
}
</script>
