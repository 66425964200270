<!--
   Home site of the app with newbooks and recommended books
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <div>
    <!--  New books slider  -->
    <h2 class="left_border">{{ $t('newBooks') }}</h2>
    <div v-if="newBooksLoading && !newBooks.length">
        <div class="pa-16 text-center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        </div>
    </div>
    <BookSlider :toDisplay="newBooks"/>

    <!--  Recommended books slider  -->
    <div v-if="logStatus">
      <div v-if="type === 'student' ">
        <h2 class="left_border">{{ $t('recommendations') }}</h2>
        <div v-if="recommendationsLoading && !recommendations.length">
          <div class="pa-16 text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
        <BookSlider :toDisplay="recommendations"/>
      </div>
    </div>

    <!-- If not logged in show a hint and import the login form   -->
    <!-- <Hint v-if="!logStatus" :text="$t('PleaseLogin')" :color="'red'" :background="'text'" :icon="'error'"></Hint> -->
    <v-dialog v-model="dialog" max-width="1100px">
      <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('Your_recommendation') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items  class="mt-10 mr-5" v-if="!logStatus">
            <LoginForm />
          </v-toolbar-items>
        </v-toolbar>
      <BookView :book="book"/>
    </v-dialog>
  </div>
</template>

<script>
import BookSlider from '@/components/BookSlider'
import BookView from '@/views/BookView'
import LoginForm from '@/components/LoginForm'
import { mapActions, mapGetters } from 'vuex'
// import Hint from '@/components/Hint'

export default {
  name: 'home',
  data () {
    return {
      book: {},
      dialog: false
    }
  },
  components: { BookSlider, BookView, LoginForm },
  methods: {
    ...mapActions(['setUser', 'getRecommendations', 'getNewBooks'])
  },
  mounted () {
    // console.log('Getting new books...')
    this.getNewBooks()

    var userData = JSON.parse(localStorage.getItem('user'))
    if (userData) {
      var currentTimestamp = Math.floor(Date.now() / 1000)
      var lastLoginTimestamp = userData.request.timestamp
      if (currentTimestamp - lastLoginTimestamp < 5 * 3600) {
        this.setUser()
        // console.log('Getting recommendations...')
        this.getRecommendations()
      }
    }
  },
  computed: {
    ...mapGetters(['newBooks', 'recommendations', 'logStatus', 'recommendationsLoading', 'newBooksLoading', 'type'])
  },
  created () {
    var search = location.search.substring(1)
    if (search) {
      this.dialog = true
      this.book = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === '' ? value : decodeURIComponent(value) })
    }
  }
}
</script>
