<!--
   Bookview site - to display a e-book recommendation.
   It will be opened when you select an e-mail link from your friend.
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
        <v-card>
            <v-row>
            <v-col md="3" sm="12" xs="12">
                <!-- Display the e-books cover -->
                <v-img
                :src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                :lazy-src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                contain
                max-height="500px"
                >
                </v-img>
                <template v-slot:placeholder>
                <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                >
                  <v-progress-circular :size="150" indeterminate color="primary"></v-progress-circular>
                </v-row>
                </template>
            </v-col>

            <!-- Display necessary information about the e-book, such as title, publisher, author and so on -->
            <v-col md="6" sm="12" xs="12">
                <h2>{{ book.title.split(':')[0] }}</h2>
                <h3>{{ book.title.split(':')[1] }}</h3>
                <h3>({{ book.year}})</h3><br>
                <h5>{{ book.publisher }}</h5>
                <h5>{{ book.author }}</h5>
                <h5>{{ book.language }}</h5>
                <h5> ISBN: {{ book.isbn }}</h5>
                <!--
                <v-rating v-model="rating" color="var(--v-secondary-base)" hover ripple clearable class="mt-5"></v-rating>
                <v-btn fab small dark hover color="error" class="mt-5">
                    <v-icon>mdi-plus</v-icon>
                </v-btn><br>
                -->

                <!-- Open book on publishers web site -->
                <v-btn class="secondary-button mt-5" @click="link(book.url_ebook)">
                    <v-icon>mdi-link</v-icon>
                    {{ $t('show') }}
                </v-btn>

                <!-- Recommend an e-book to a friend -->
                <!-- Fill in an e-mail to recommend an e-book to a friend -->
                <EMailOverlay :sendBook="this.book"/>

                <div v-if="!logStatus" class="headline red--text mt-7">{{ $t('PleaseLogin') }}</div>
            </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import EMailOverlay from '@/components/EMailOverlay'
import { mapGetters } from 'vuex'

export default {
  name: 'bookview',
  components: { EMailOverlay },
  props: {
    book: Object
  },
  data () {
    return {
      rating: 0
    }
  },
  computed: {
    ...mapGetters(['logStatus'])
  },
  methods: {
    // ...mapActions(['sendRecommendation']),
    // sendRec () {
    //   this.sendRecommendation()
    // },
    // closeDialog () {
    //   this.dialog = false
    // },
    /**
     * Create a link to the e-book on the publishers web site
     * @param link the raw link
     * @param linkPda the full link
     */
    link: function (link, linkPda) {
      // console.log(link, linkPda)
      window.open(link)
    }
  }
}
</script>
