<!--
   Book grid component - show all founded books in a grid with responsive design
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->

<template>
    <div>
      <v-container class="pa-0">
        <!-- If no books are found show a hint -->
        <Hint v-if="this.$store.getters.searchedBooks === null" :text="$t('noResult')" :color="'red'" :background="'text'" :icon="'error'"></Hint>
        <!-- Create cards for each book in list with a title and a book cover -->
        <v-row v-if="this.$store.getters.searchedBooks !== null">
          <v-card
              v-for="(book, i) in bookList" :key="book.key"
              class="mx-3 mb-5"
              width="250"
              hover
              :ripple="{ class: 'primary--text' }"
              :to="{ name: 'bookDetail', query: {bookList: bookList, index: i} }"
          >
            <v-hover v-slot:default="{ hover }">
              <v-img
                  href="/book"
                  :data-isbn="book.isbn.split(',')[0]"
                  :src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                  :lazy-src="book.cover ? book.cover : require('@/assets/placeholder-vertical.jpg')"
                  height="350"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular :size="150" indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
                <v-expand-transition>
                  <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out secondary_gradient v-card--reveal white--text"
                      style="height: 38%;"
                  >
                    {{ $t('show')}}
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-btn
                absolute
                class="btn_card_link secondary-button white--text"
                fab
                right
                ripple
                :title="$t('show')"
                @click="link(book.url_ebook, book.doi)"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
            <v-card-title class="text-wrap">
              {{ book.title.split(':')[0] }}
            </v-card-title>
          </v-card>
        </v-row>
      </v-container>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import Hint from '@/components/Hint'
import { BACKEND_URL } from '@/store/index.js'
export default {
  name: 'BookGrid',
  components: { Hint },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['searchedBooks', 'fulltextLoading']),
    bookList () {
      if (this.$parent.isFindParent) {
        return this.$store.getters.searchedBooks
      } else {
        return this.$store.getters.foundBooks
      }
    }
  },
  methods: {
    // create a link to the publisher site from the given e-book
    ...mapActions(['setFulltextLoading']),
    link: function (url, doi) {
      this.setFulltextLoading(true)
      // console.log('URL: ' + url)
      // console.log('DOI:' + doi)
      // console.log('decoded URI: ' + encodeURIComponent(doi))
      let fulltextUrl = BACKEND_URL + '/fulltext/' + encodeURIComponent(doi)
      // console.log(fulltextUrl)
      if (doi !== '') {
        axios.get(fulltextUrl, {
          proxy: {
            host: 'proxy.th-wildau.de',
            port: 8080
          }
        })
          .then(response => {
            // console.log(response.data)
            if (response.data === 'useStandardUrl') {
              window.open(url, '_blank')
            } else {
              window.open(decodeURIComponent(response.data))
            }
          })
          .catch(error => {
            console.log(error.message)
            window.open(url, '_blank')
          })
      } else {
        window.open(url, '_blank')
      }
      this.setFulltextLoading(true)
    }
  }
}
</script>
