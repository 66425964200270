<!--
   Hint component - makes it possible to print a hint for the user overall in the app with same style
   This component workes with props, so that you can commmit:
   - a text for the hint
   - a color for the text
   - the background style (use value text for a light background color)
   - also an icon (e.g. error, warning, success)
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <v-container>
    <v-row>
      <v-col cols class="col-xl-5 col-lg-5 col-md-8 col-sm-12">
        <v-alert
            outlined
            :color=this.color
            :text=this.background
            :type=this.icon
            dense
            transition="scale-transition"
        >
          {{this.text}}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    text: String, // Hint text
    background: String, // Set background for hint if 'text' is given
    color: String, // Set the color of the hint
    icon: String // Set the icon of the hint - e.g. 'error'
  },
  name: 'Hint',
  data () {
    return {
    }
  }
}
</script>
