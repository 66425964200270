<!--
   Help site - get informations about the actual functions in the app
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-container>
        <!-- Create a help section for each function -->
        <v-row v-for="item in functions.length" :key="item">
            <v-col class="col-1"></v-col>
            <v-col>
                <v-banner>
                    <h2>{{ $t('' + functions[item-1] + '') }} </h2>
                </v-banner>
                <p class="paragraph p">{{ $t('' + functions[item-1] + '' + 'Text')}}</p>
            </v-col>
            <v-col class="col-1"></v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Help',
  data: () => ({
    functions: [ // the actual functions in the app
      'allRecommendations',
      'searchRecommendations',
      'find',
      'search'
    ]
  })
}
</script>
