import axios from 'axios'
import cengine from '@/cengine.js'
import { BACKEND_URL } from '@/store/index.js'
// import { toISOString } from 'core-js/core/date'

const state = {
  newBooks: [], // store the books which the library has new in its catalogue
  searchedBooks: [], // store the books from the search by catalogue function
  lentPrintBooks: [], // store the lent printed books from the API related to a RVK-Notation
  foundBooks: [], // store the books related to a shelf in the library
  hitsPerPage: '',
  shelfID: '',
  qrPosition: '',
  theme: '',
  sorting: '',
  date: '',
  language: '',
  newBooksLoading: false,
  fulltextLoading: false,
  imprint: [],
  dataProtection: []
  // cancelSource: ''
}

// Create getters for necessary variables
const getters = {
  newBooks: (state) => state.newBooks,
  searchedBooks: (state) => state.searchedBooks,
  foundBooks: (state) => state.foundBooks,
  lentPrintBooks: (state) => state.lentPrintBooks,
  hits: (state) => state.hitsPerPage,
  shelf: (state) => state.shelfID,
  qrPosition: (state) => state.qrPosition,
  newBooksLoading: (state) => state.newBooksLoading,
  fulltextLoading: (state) => state.fulltextLoading,
  getImprint: state => state.imprint,
  getDataProtection: state => state.dataProtection
  // getCancelSource: state => state.cancelSource
}

// Create all actions for the backend
const actions = {
  /**
   * Fetch the e-books related to a shelf in the library
   * @param commit
   * @param clientData a JSON Object with parameters, such as numberOfResults, shelfID
   * @returns {Promise<void>}
   */
  async fetchBooks ({ commit }, clientData) {
    let qrPosition = clientData.qrCode.replace(/ /g, '%20')
    // console.log('fetchBooks, shelfs: ' + qrPosition)
    let hits = clientData.hits
    let url = BACKEND_URL + '/booksqrcode/' + qrPosition + '?hitsPerPage=' + hits
    // console.log(shelf)
    // console.log(url)
    await axios.get(url).then(function (response) {
      let allBooks = response.data // temp store for the backends result

      // Get the covers of each e-book
      for (let book of allBooks) {
        cengine.getBookCover(book.isbn.split(',')[0], cUrl => {
          if (cUrl === 'false') {
            book['cover'] = undefined
          } else {
            book['cover'] = cUrl
          }
        })
      }
      // commit the temp e-book datat to the right store array to make them accessible in other components
      // console.log(allBooks)
      commit('setFoundBooks', allBooks)
      return true
    }).catch(function () {
      console.error('Failed getting books to a shelf area...')
      return false
    })
  },

  /**
   * Fetch the e-books for the FindEBooks component
   * @param commit
   * @param clientData a JSON Object with parameters, such as numberOfResults, search string and so on
   * @returns {Promise<void>}
   */
  async searchBooks ({ commit, state }, clientData) {
    // console.log('CancelToken: ' + state.cancelSource)
    /*
    if (state.cancelSource !== '') {
      state.cancelSource.cancel('Cancellation done')
      console.log('cancel request done')
      commit('setCancelSource', '')
      console.log('CancelSource nach cancel: ' + state.cancelSource)
    }
    */
    // commit('setCancelSource', axios.CancelToken.source())
    // console.log('CancelToken: ' + state.cancelSource.token)
    let theme = clientData.theme
    // console.log(clientData.theme)
    let hits = clientData.hits
    let sorting = clientData.sort
    let date = clientData.published
    let language = clientData.language
    let url = BACKEND_URL + '/search/' + theme + '?hitsPerPage=' + hits + '&date=' + date + '&sort=' + sorting + '&lang=' + language
    // console.log(url)
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    await axios.get(url /* ,{ cancelToken: state.cancelSource.token } */ /* ,{ timeout: 3000 } */).then(function (response) {
      let allBooks = response.data // temp store for the backends result
      // console.log(allBooks)
      // Get the covers of each e-book
      if (allBooks.length > 0) {
        for (let book of allBooks) {
          cengine.getBookCover(book.isbn.split(',')[0], function (cUrl) {
            if (cUrl === 'false') {
              book['cover'] = undefined
            } else {
              book['cover'] = cUrl
            }
          })
        }
        // console.log('token: ' + state.cancelSource.token)
        // commit('setCancelSource', '')
        // console.log('CancelSource after successfull request: ' + state.cancelSource)
        // commit the temp e-book data to the right store array to make them accessible in other components
        // console.log(allBooks)
        commit('setSearchedBooks', allBooks)
        return true
      } else {
        commit('setSearchedBooks', null)
        return true
      }
    }).catch(function (e) {
      console.log(e)
      console.error('Failed getting books...')
      return false
    })
  },
  /**
   * Fetch the new e-books in the libraries catalogue
   * @param commit
   * @param clientData a JSON Object with parameters, such as numberOfResults
   * @returns {Promise<void>}
   */
  async getNewBooks ({ commit, state }) {
    // console.log('Loading: ' + state.newBooksLoading)
    commit('setNewBooksLoading', true)
    let url = BACKEND_URL + '/newbooks'
    // console.log(url)
    await axios.get(url).then(function (response) {
      let allBooks = response.data // temp store for the backends result

      // Get the covers of each e-book
      for (let book of allBooks) {
        cengine.getBookCover(book.isbn.split(',')[0], cUrl => {
          if (cUrl === 'false') {
            book['cover'] = undefined
          } else {
            // console.log(cUrl)
            book['cover'] = cUrl
          }
        })
      }
      // commit the temp e-book data to the right store array to make them accessible in other components
      // console.log('New books loaded...')
      commit('setNewBooks', allBooks)
      commit('setNewBooksLoading', false)
      return true
    }).catch(function () {
      console.error('Failed getting new books...')
      return false
    })
  },
  async fetchImprintAndDP ({ commit }) {
    // const URL = 'https://icampus.th-wildau.de/icampus/home/privacy/views/privacycontent'
    // const URL = 'https://icampus.th-wildau.de/cms/de/datenschutz'
    const URL = 'https://icampus.th-wildau.de/cms/en/imprint'
    await axios
      .get(URL)
      .then(function (response) {
        let data = response.data
        // console.log(data[1])
        commit('setImprint', data[0].body.replace('\n', ''))
        commit('setDataProtection', data[1].body.replace('\n', '').replace(/<p>&nbsp;<\/p>/g, '').replace(/<p><strong(.*?)<\/p>/g, ''))
        return true
      })
      .catch(function () {
        return false
      })
  },

  /**
   * Fetch the lent printed books related to a shelf in the library
   * @param commit
   * @param clientData a JSON Object with parameters, such as numberOfResults, shelfID
   * @returns {Promise<void>}
   */
  async fetchLentBooks ({ commit }, clientData) {
    // console.log('fetchLentBooks is called')
    let qrPosition = clientData.qrCode.replace(' ', '%20')
    // console.log('fetchBooks, shelfs: ' + qrPosition)
    let hits = clientData.hits
    let url = BACKEND_URL + '/lentBooks/' + qrPosition + '?hitsPerPage=' + hits
    // console.log(shelf)
    await axios.get(url).then(function (response) {
      let allBooks = response.data // temp store for the backends result
      // console.log(allBooks)
      if (allBooks.length > 0) {
        // Get the covers of each e-book
        for (let book of allBooks) {
          if (book.isbn !== ' ') {
            cengine.getBookCover(book.isbn.split(',')[0], cUrl => {
              if (cUrl === 'false') {
                book['cover'] = undefined
              } else {
                book['cover'] = cUrl
              }
            })
          }
        }
        // commit the temp e-book datat to the right store array to make them accessible in other components
        // console.log(allBooks)
        commit('setLentPrintBooks', allBooks)
        return true
      } else {
        commit('setLentPrintBooks', null)
        return true
      }
    }).catch(function () {
      console.error('Failed getting books to a shelf area...')
      return false
    })
  },

  async link ({ commit }, url, doi) {
    commit('setFulltextLoading', true)
    // console.log('URL: ' + url)
    // console.log('DOI:' + doi)
    // console.log('decoded URI: ' + encodeURIComponent(doi))
    let fulltextUrl = BACKEND_URL + '/fulltext/' + encodeURIComponent(doi)
    // console.log(fulltextUrl)
    if (doi !== '' && url.startsWith('https://doi')) {
      axios.get(fulltextUrl, {
        proxy: {
          host: 'proxy.th-wildau.de',
          port: 8080
        }
      })
        .then(response => {
          // console.log(response.data)
          if (response.data === 'useStandardUrl') {
            window.open(url, '_blank')
          } else {
            window.open(decodeURIComponent(response.data))
          }
        })
        .catch(error => {
          console.log(error.message)
          window.open(url, '_blank')
        })
    } else {
      window.open(url, '_blank')
    }
    commit('setFulltextLoading', false)
  },

  async setHitsPerPage ({ commit }, hitsPerPage) {
    // console.log('Trefferzahl: ' + hitsPerPage)
    commit('setHitsPerPage', hitsPerPage)
  },
  async setShelfID ({ commit }, shelfID) {
    commit('setShelfID', shelfID)
  },
  async setQrPosition ({ commit }, qrPosition) {
    commit('setQrPosition', qrPosition)
  },
  async setSorting ({ commit }, area) {
    commit('setSorting', area)
  },
  async setTheme ({ commit }, theme) {
    commit('setTheme', theme)
  },
  async setDate ({ commit }, date) {
    commit('setDate', date)
  },
  async setLanguage ({ commit }, language) {
    commit('setLanguage', language)
  },
  async setFulltextLoading ({ commit }, fulltextLoading) {
    commit('setFulltextLoading', fulltextLoading)
  }
}

const mutations = {
  setNewBooks: (state, books) => (state.newBooks = books),
  setFoundBooks: (state, books) => (state.foundBooks = books),
  setSearchedBooks: (state, books) => (state.searchedBooks = books),
  setLentPrintBooks: (state, books) => (state.lentPrintBooks = books),
  setHitsPerPage: (state, hitsPerPage) => (state.hitsPerPage = hitsPerPage),
  setShelfID: (state, shelfID) => (state.shelfID = shelfID),
  setQrPosition: (state, qrPosition) => (state.qrPosition = qrPosition),
  setTheme: (state, theme) => (state.theme = theme),
  setSorting: (state, area) => (state.area = area),
  setDate: (state, date) => (state.date = date),
  setLanguage: (state, language) => (state.language = language),
  setNewBooksLoading: (state, status) => (state.newBooksLoading = status),
  setFulltextLoading: (state, status) => (state.fulltextLoading = status),
  setImprint: (state, data) => (state.imprint = data),
  setDataProtection: (state, data) => (state.dataProtection = data),
  setCancelSource: (state, data) => (state.cancelSource = data)
}

export default {
  state,
  getters,
  actions,
  mutations
}
