<!--
   Privacy site
   @author Christopher Grätz
   @version 1.0 / 2021-01-07yy
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-1"></v-col>
        <v-col class="p col-10">
          <h1> {{ $t('privacy') }}</h1>
          <!--<h2 class="pa-3">{{ $t("privacy") }}</h2>-->
          <div v-html="$t('privacy_text')"></div>
          <!--<v-divider></v-divider><br>
          <div v-html="dataProtection"> </div> -->
        </v-col>
        <!--<v-col class="col-1"></v-col>-->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data () {
    return {

    }
  },
  computed: {
    dataProtection () {
      return this.$store.getters.getDataProtection
    }
  }
}
</script>
