<!--
   Imprint site
   @author Christopher Grätz
   @version 1.0 / 2021-01-07
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-1"></v-col>
        <v-col class="col-10">
          <!--<div v-html="$t('legal_notice')"></div>-->
          <h1>{{ $t("legal_notice") }} </h1>
          <h2>{{ $t("ln_name") }}</h2>
          <div v-html="$t('ln_contact')"></div><br>
          <h2>{{ $t("ln_represented_by_header") }}</h2>
          <div>{{ $t("ln_represented_by_text") }}</div><br>
          <h2>{{ $t("ln_supervisor_header") }}</h2>
          <div>{{ $t("ln_supervisor_text") }}</div><br>
          <h2>{{ $t("ln_univ_comm_header") }}</h2>
          <div v-html="$t('ln_univ_comm_text')"></div><br>
          <!--<div>{{ $t("ln_univ_comm_text") }}</div><br>-->
          <h2>{{ $t("ln_techn_suppport_header") }}</h2>
          <div>{{ $t("ln_techn_support_text") }}</div><br>
          <h2>{{ $t("ln_disclaimer_header") }}</h2>
          <div v-html="$t('ln_disclaimer_text')"></div><br>
          <!--<div>{{ $t("ln_disclaimer_text") }}</div><br>-->
          <h2>{{ $t("ln_copyright_header") }}</h2>
          <div v-html="$t('ln_copyright_text')"></div> <br>
          <!--<div>{{ $t("ln_copyright_text") }}</div><br>-->
          <!--<h2 class="pa-3">{{ $t("imprint") }}</h2>-->
          <v-divider></v-divider><br>
          <!--<div v-html="imprint"></div>-->
        </v-col>
        <v-col class="col-1"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
  data () {
    return {
    }
  },
  computed: {
    imprint () {
      return this.$store.getters.getImprint
    }
  }
}
</script>
