<!--
   Location component - to get the location of a shelf in the library via a qr-code scan
   This component presents a button for starting the location and a video field to show the devices camera
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-row class="justify-center">
        <v-col cols="12" class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
            <div class="my-2">
                <v-btn class="secondary-button" dark @click="scannerOn = !scannerOn">{{ $t('scanQR') }}</v-btn>
            </div>
            <!-- <v-btn class="secondary-button" dark>{{ $t('locateMe') }}</v-btn> -->
            <v-spacer></v-spacer>
            <v-container>
                <label >{{ $t('numResults') }}</label>
                <v-select v-model="selected"
                          :items="counter"
                          outlined
                          prepend-icon="mdi-counter"
                          dense
                          hint="Choose the number of displayed books"
                          type="number"
                          :label="$t('number')"
                          @input="updateFilter">
                    <!--<option v-for="count in counter" :key="count.id"  :value="count.number">{{count.number}}</option>-->
                </v-select>
            </v-container>
        </v-col>
<!--        <v-spacer v-if="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl"></v-spacer>-->
        <v-col cols="12" class="col-xl-5 col-lg-5 col-md-4 col-sm-6">
            <v-container class="qrCodeScanner">
                <div v-if="scannerOn">
                    <QrCodeScanner />
                </div>
                <div v-else>
                    <v-img src="@/assets/Barcode.jpg" width="200" height:auto>
                    </v-img>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import QrCodeScanner from '@/components/QrCodeScanner'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Location',
  components: { QrCodeScanner },
  data () {
    return {
      generateGrid: false,
      counter: [10, 20, 50, 100],
      selected: 20,
      scannerOn: false
    }
  },
  computed: {
    ...mapGetters(['shelf'])
  },
  methods: {
    ...mapActions(['fetchBooks', 'fetchLentBooks', 'setHitsPerPage']),
    async updateFilter (e) {
      this.setHitsPerPage(e)
      var clientData = { 'shelf': this.shelf, 'hits': e }
      // this.fetchLentBooks(clientData)
      this.fetchBooks(clientData)
    }
  },
  created () {
    this.setHitsPerPage(this.selected)
  }
}
</script>
