<!--
   Book slider component - show all founded books in a slider component
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <v-sheet
    class="slider mx-auto"
    elevation="0"
  >
    <v-slide-group
      class="pa-0"
      active-class="success"
      show-arrows
    >
      <!-- Create a book card for all books in list -->
      <v-slide-item
        v-for="(book, i) in toDisplay "
        :key="i"
        v-slot:default="{ active }"
      >
        <v-card
          :color="active ? undefined : 'white lighten-1'"
          class="mt-12 mb-12 mr-4 scaled"
          height="300"
          width="200"
          hover
          :to="{ name: 'bookDetail', query: {bookList: toDisplay, index: i} }"
          :data-isbn="book.isbn.split(',')[0]"
          :ripple="{ class: 'primary--text' }"
        >
          <v-card-text v-if="!book.cover">
            <h3>{{ book.title.split(':')[0] }}</h3>
            <v-icon small class="bottom">mdi-image-off-outline</v-icon>
          </v-card-text>

          <v-img v-else
            :src="book.cover ? book.cover : makeOverlay(book.cover)"
            :lazy-src="book.cover ? book.cover : makeOverlay(book.cover)"
            aspect-ratio="0.65"
          >
            <template v-slot:placeholder>
              <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
              >
               <v-progress-circular :size="150" indeterminate color="primary"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
              <v-icon
                v-if="active"
                color="white"
                size="50"
                v-text="'mdi-close-circle-outline'"
              ></v-icon>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>

export default {
  props: {
    toDisplay: Array
  },
  name: 'BookSlider',
  data () {
    return {
    }
  }
}
</script>
