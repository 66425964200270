export default {
  flagCode: 'us',
  languages: {
    de: 'German',
    en: 'English'
  },

  PleaseLogin: 'Please login in to get, search and send recommendations.',
  PleaseLogin2: 'Please login in to send recommendations.',
  PleaseLoginReservation: 'Please login to place a reservation or send a recommendation',
  Recommend: 'Recommend it to a friend',
  Reservate: 'Reservation',
  friend: 'Friend\'s Email',
  noRecommendations: 'No reommendations found :(',
  noResults: 'No results found',
  noResultLentBooks: 'No lent books found',
  Your_recommendation: 'Your Reocmmendation',
  reservation: 'Book a request on this item',
  doing_reservation: 'Reservation in progress',
  reservation_successfull: 'Reservation successfull :)',
  reservation_failed: 'Reservation failed :(',
  reservationRequest: 'Do you want to place a reservation on this book?',

  newBooks: 'New Books',
  mostUsedBooks: 'Most read Books',
  recommendations: 'Your Top Recommendations',
  searchResults: 'Results',
  lentBooks: 'Temporary lent books',

  scanQR: 'Scan QR-Code',
  locateMe: 'Locate Me',
  numResults: 'Number of results',
  number: 'Number',

  home: 'HOME',
  myBooks: 'My Books',
  find: 'Scan QR-Code',
  search: 'Search E-Books',
  help: 'Get Help',
  contact: 'Contact',
  allRecommendations: 'All Recommendations',
  searchRecommendations: 'Search Recommendations',
  myRecommendations: 'My Recommendations',
  imprint: 'Imprint',
  privacy: 'Privacy',
  privacy_text: 'The following information will be stored after login: <br> username, role (i.e. student), email, course + year, library-card-number <br> After logout all these information are going to be deleted from the browser.',

  allRecommendationsText: 'With the help of this function you have the possibility to get e-book recommendations for a ' +
    'certain semester. To use this function, you must first log in to see the corresponding menu item. Once you are ' +
    'logged in, it is possible to select a semester. Only the semesters that are possible in your degree programme will ' +
    'be suggested.',
  searchRecommendationsText: 'Using these functions you can search for e-book recommendations for all courses of study ' +
    'offered at the TH-Wildau. These are taken from the respective module descriptions. Thus you also have the ' +
    'possibility to view all e-book recommendations for a study programme. There is also an integrated filter function ' +
    'that allows you to limit your search to individual semesters and modules in that semester in order to obtain special ' +
    'recommendations for a module.',
  findText: 'The function "Find e-books" is an additional service of the library of the TH Wildau. It gives you the ' +
    'opportunity to find and display e-books that match a shelf area of the library.',
  searchText: 'This function allows you to search for e-books via a catalogue using search terms. It is also possible ' +
    'to narrow down the search. The following functions are available for this purpose:\n\n\t - select sorting\n\t - limit the ' +
    'period of publication\n\t - select the language of the e-book\n\nIn addition, the number of hits can also be ' +
    'adjusted according to your wishes. If the number of books displayed does not correspond to the number set, this is ' +
    'because not enough e-books were found',

  back: 'Go back',
  nextBook: 'Next E-Book',
  nextPrintBook: 'Next Book',
  previousBook: 'Previous E-Book',
  previousPrintBook: 'Previous Book',
  show: 'Show E-Book',

  lentUntil: 'Duedate: ',
  duedate_hint: 'Please note that duedate can differ if more items are lent or if there are already reservations on this title',

  selectTheme: 'Insert theme:',
  selectSort: 'Select sorting:',
  time: 'Publishing time:',
  language: 'Language',
  studiengang: 'Major',
  semester: 'Semester',
  modul: 'Modules',

  invalid_email: 'invalid email-address',

  btn_login: 'Login',
  btn_logout: 'Logout',
  btn_cancel: 'Cancel',
  btn_send: 'Send',
  btn_submit: 'Submit',
  btn_reset: 'Reset',

  set_personal: 'Personal',
  set_notifications: 'Notifications',
  switch_push: 'Push-notifications',
  switch_email: 'Email notifications',

  qrError: 'QR Code not available',
  qrSuccess: 'QR code available',

  siteSettings: 'Settings',
  languagePicker: 'Change language',
  searchOnSite: 'Search on site',

  searchBooks: 'Search books',

  func_search: 'Function search for e-books in the shelf',
  func_find: 'Function find e-books by theme',
  text_func_search: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',

  placeholder_theme: 'Please insert a search query',
  placeholder_sortCrit: 'Please choose a sorting',
  placeholder_date: 'Please choose a published time',
  placeholder_couter: 'Select the number of results',
  placeholder_language: 'Select the language of the e-books',
  placeholder_studiengang: 'Select major of study',
  placeholder_semester: 'Select semester',
  placeholder_modul: 'Select modul',

  author_up: 'Author ascending',
  author_down: 'Author descending',
  score: 'Relevance',
  pub_up: 'Year ascending',
  pub_down: 'Year descending',
  title_up: 'Title ascending',
  title_down: 'Title descending',

  notFound_headline: 'SORRY',
  notFound_text: 'we couldn\'t find that page.\n' +
      '            Try searching or go to the ',

  user_login: 'Login',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email address',
  topics: 'Select topics you\'r interested in',
  yourMessage: 'Your message for us ...',

  required_field: '* indicates required field',

  login_failed: 'Wrong username or password',

  imprint0: 'Responsible for these internet pages according to § 5 Telemediengesetz (TMG):\n\nProf. Dr. rer. nat. Janett Mohnke\nTH Wildau\nHochschulring 1\n15745 Wildau\n\nTel.: +49 3375 508 291\nMail: janett.mohnke@th-wildau.de\nHaus 14, Raum A228',
  imprintHead1: 'Disclaimer',
  imprint1: 'This imprint is only valid for those documents of the WWW server of TH Wildau (icampus.th-wildau.de), which refer to this page. For all other pages on this WWW server, editorial responsibility lies with the respective offices or persons who are registered as responsible for the page or who created the page.\n\nThe Technische Hochschule Wildau has no influence on the content of Internet pages of other providers to which the WWW pages of the Technische Hochschule Wildau refer by link. The operators of the other websites are solely responsible for the content of these sites. Despite having checked the content within the legally required framework, the Technische Hochschule Wildau must therefore decline any responsibility for the content of these links or the linked site.',
  imprintHead2: 'Copyright',
  imprint2: 'The contents and works on these pages created by the site operators are subject to German copyright law. The duplication, editing, distribution and any kind of utilization outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use.\n\nInsofar as the content on this site was not created by the operator, the copyrights of third parties are observed. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such contents immediately.',
  imprintHead3: 'Liability for contents',
  imprint3: 'As a service provider, we are responsible for our own content on these pages according to § 7 para.1 TMG (German Telemedia Act) and general laws. According to §§ 8 to 10 TMG we are not obliged to monitor transmitted or stored information or to investigate circumstances that indicate illegal activity.\n\nObligations to remove or block the use of information according to general laws remain unaffected. However, liability in this respect is only possible from the time of knowledge of a concrete infringement. If we become aware of any such violations, we will remove the content in question immediately.',
  imprintHead4: 'Liability for links',
  imprint4: 'Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore we cannot assume any liability for these external contents. The respective provider or operator of the sites is always responsible for the contents of the linked sites. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not identified at the time of linking.\n\nHowever, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.',

  legal_notice: 'Legal notice',
  ln_name: 'Technical University of Applied Sciences Wildau',
  ln_contact: 'Hochschulring 1 <br> 15745 Wildau <br><br> Tel: +49 (0) 3375 / 508 - 300 (switchboard) <br> Email: hochschulkommunikation(at)th-wildau.de',
  ln_represented_by_header: 'Represented by:',
  ln_represented_by_text: 'The Technical University of Applied Sciences Wildau is a statutory corporation. Its external representative is the President, Prof. Ulrike Tippe.',
  ln_supervisor_header: 'Supervisory authority:',
  ln_supervisor_text: 'The university is subject to legal supervision by the Ministry for Science, Research and Culture of the Federal State of Brandenburg.',
  ln_univ_comm_header: 'Content (Website & Social Media)',
  ln_univ_comm_text: 'Have you got any questions or comments about the web pages of the TH Wildau? The Online Editorial team will be happy to help you:<br> <h3>Centre for University Communications</h3> <br> Susanne Baron, Mike Lange <br> website(at)th-wildau.de <br> Hochschulring 1 <br> 15745 Wildau',
  ln_techn_suppport_header: 'Technical Support: ',
  ln_techn_support_text: 'Technical support for the central web pages of the Technical University of Applied Sciences Wildau is incumbent upon the University IT centre, unless stated otherwise.',
  ln_disclaimer_header: 'Disclaimer',
  ln_disclaimer_text: 'This legal notice is only applicable to the documents on the web server of TH Wildau (www.th-wildau.de) which link to this page. For all other pages on this web server, editorial responsibility rests with the respective bodies or persons who are listed on the website as responsible or who created the site. <br>The Technical University of Applied Sciences Wildau has no influence over the contents of the websites of other providers which are linked from the web pages of the Technical University of Applied Sciences Wildau. The operators of the other websites are exclusively responsible for the contents of such pages. Despite careful inspection of the contents in accordance with legal requirements, the Technical University of Applied Sciences Wildau must therefore waive all responsibility for the content of these links or the linked sites.',
  ln_copyright_header: 'Copyright',
  ln_copyright_text: 'The contents and works on these pages created by the site operator are protected by German copyright. The reproduction, processing, dissemination of any kind, and every type of utilisation beyond the confines of the copyright law require the written consent of the respective author or creator. Downloading and copying this website is only permitted for private and not for commercial use. <br>Insofar as the contents of this page were not created by the operator, third-party copyrights will be observed. In particular the content of third parties is identified as such. Should you still have reason to believe that a copyright violation has occurred, please notify us thereof. As soon as we become aware of a legal violation we will remove such contents immediately.'
}
