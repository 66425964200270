/**
 * Initialise all plugins, such as language support, vuetify store and router
 * @author Christopher Grätz
 * @version 1.0 / 2020-11-27
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import { i18n } from './plugins/i18n.js'
import FlagIcon from 'vue-flag-icon'

// import css files
import './assets/css/desktopStyle.css'
import vuetify from './plugins/vuetify'

Vue.use(FlagIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
