<!--
   All recommendations site - to get recommendations of your study programme by a given semester
   @author Christopher Grätz, Omar Attia
   @version 1.0 / 2020-11-25
-->
<template>
  <div>
    <div v-if="logStatus">
      <v-container>
      <v-row>
        <v-col cols md="3" sm="13">
          <!-- Select a semester -->
          <label>{{ $t('semester') }}</label>
          <v-select
                  v-model="selectedSemester"
                  class="select"
                  :items="semesterCount"
                  outlined
                  dense
                  :placeholder="$t('placeholder_semester')"
                  clearable
                  @input="updateFilter"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>

    <!-- Create for each course in the semester a book slider   -->
    <div v-if="recommendationsLoading && !semRecommendations.length">
        <div class="pa-16 text-center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        </div>
      </div>
    <v-container v-for="(module, i) in semRecommendations" :key="i">
      <div v-if="module.recBooks.length">
        <h2 class="left_border">{{ module.title }}</h2>
        <BookSlider :toDisplay="module.recBooks"/>
      </div>
    </v-container>
    </div>
    <div v-else>
      <div class="headline red--text mt-7">{{ $t('PleaseLogin') }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BookSlider from '@/components/BookSlider'

export default {
  name: 'AllRecommendations',
  components: { BookSlider },
  data () {
    return {
      selectedSemester: null
    }
  },
  methods: {
    ...mapActions(['getSemRecommendations', 'setUser', 'getNumberOfSemesters']),
    /**
     * Update the search query when the user makes a new input in the dropdown
     * @param e
     * @returns {Promise<void>}
     */
    async  updateFilter (e) {
      var requestOptions = {
        'studiengang': this.studiengang,
        'seminargruppe': this.seminargruppe,
        'token': this.token,
        'requestedSemester': e
      }
      this.getSemRecommendations(requestOptions)
    }
  },
  computed: {
    ...mapGetters(['semRecommendations', 'logStatus', 'numberOfSemesters', 'studiengang', 'seminargruppe', 'token', 'recommendationsLoading']),
    /**
     * Preset the semester dropdown with the standard number of semester in your study programme
     * @returns {[]}
     */
    semesterCount () {
      var list = []
      for (var i = 1; i < this.numberOfSemesters + 1; i++) {
        list.push(i)
      }
      return list
    }
  },
  mounted () {
    if (localStorage.getItem('user')) {
      this.setUser()
      this.getNumberOfSemesters()
    }
  }
}
</script>
