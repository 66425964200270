export default {
  flagCode: 'de',
  languages: {
    de: 'Deutsch',
    en: 'Englisch'
  },

  PleaseLogin: 'Bitte loggen Sie sich ein, um Empfehlungen zu E-Books zu sehen oder senden zu können.',
  PleaseLoginReservation: 'Bitte loggen Sie sich ein, um Vormerkungen aufgeben oder Buchempfehlungen senden zu können',
  Recommend: 'Einem Freund empfehlen',
  Reservate: 'Vormerkung für diesen Band',
  friend: 'Empfehlung per E-Mail versenden',
  noRecommendations: 'Keine Empfehlungen gefunden :(',
  noResult: 'Ihre Suche hat leider keine Treffer ergeben :(',
  noResultLentBooks: 'Keine entliehenen Bände gefunden',
  Your_recommendation: 'Ihre Empfehlung',
  reservation: 'Vormerkung',
  doing_reservation: 'Vormerkung wird ausgeführt',
  reservation_successfull: 'Vormerkung erfolgreich',
  reservation_failed: 'Vormerkung fehlgeschlagen',
  reservationRequest: 'Wollen Sie eine Vormerkung für diesen Band aufgeben?',

  newBooks: 'Neu eingetroffen',
  mostUsedBooks: 'Meistverwendete E-Books',
  recommendations: 'Top-Empfehlungen für Sie',
  searchResults: 'Treffer',
  lentBooks: 'Derzeit entliehene Bände',

  scanQR: 'QR-Code scannen',
  locateMe: 'Mich lokalisieren',
  numResults: 'Anzahl der Treffer',
  number: 'Anzahl',

  home: 'HOME',
  myBooks: 'Meine E-Books',
  find: 'QR-Code scannen',
  search: 'E-Books suchen',
  help: 'Hilfe',
  contact: 'Kontakt',
  allRecommendations: 'Alle Empfehlungen',
  searchRecommendations: 'Empfehlungen suchen',
  myRecommendations: 'Meine Empfehlungen',
  imprint: 'Impressum',
  privacy: 'Datenschutz',
  privacy_text: 'Folgende Daten von Ihnen werden nach Anmeldung lokal im Browser gespeichert: <br> Name, Rolle, E-Mail-Adresse, Studiengang, Matrikel, Nummer des Bibliotheksausweises <br> Nach Abmeldung werden alle Informationen wieder gelöscht.',

  allRecommendationsText: 'Mit Hilfe dieser Funktion haben Sie die Möglichkeit E-Book Empfehlungen ' +
    'zu einem bestimmten Semester zu bekommen. Um diese Funktion nutzen zu können, müssen Sie sich ' +
    'zunächst einloggen, um den entsprechenden Menüpunkt zu sehen. Sobald Sie eingeloggt sind, ist es möglich ' +
    'ein Semester auszuwählen. Dabei werden Ihnen nur die Semester vorgeschlagen, die in ihrem Studiengang möglich sind.',
  searchRecommendationsText: 'Über diese Funktionen können Sie nach E-Book-Empfehlungen zu allen an der TH-Wildau ' +
    'angebotenen Studiengängen nachvollziehen. Diese stammen aus dem jeweiligen Modulbeschreibungen. Somit haben Sie ' +
    'die Möglichkeit auch alle E-Book-Empfehlungen zu einem Studiengang anzusehen. Dabei ist weiterhin eine Filter-Funktion ' +
    'integriert, mit welcher Sie die Suche auf einzelne Semester und Module in diesem Semester eingrenzen können, um spezielle ' +
    'Empfehlungen zu einem Modul zu erhalten.',
  findText: 'Die Funktion "E-Books finden" ist eine Zusatzdienstleistung der Bibliothek der TH Wildau. Hiermit haben Sie ' +
    'die Möglichkeit passende E-Books zu einem Regalbereich der Bibliothek zu erhalten und sich diese anzuzeigen.',
  searchText: 'Mit Hilfe dieser Funktion können Sie nach E-Books über einen Katalog suchen mit der Nutzung von Suchbegriffen. ' +
    'Darüber hinaus ist es möglich die Suche einzugrenzen. Dazu stehen folgende Funktionen zur Verfügung:\n\n\t- Sortierung wählen\n\t' +
    '- Zeitraum der Veröffentlichung einschränken\n\t- Sprache des E-Books auswählen\n\nDarüber hinaus kann auch die ' +
    'Trefferanzahl nach den Wünschen angepasst werden. Sollte die Anzahl an angezeigten  Bücher nicht der eingestellten ' +
    'Anzahl entsprechen liegt dies daran, dass nicht genügend E-Books gefunden wurden.',

  back: 'Zurück zur Übersicht',
  nextBook: 'Nächstes E-Book',
  nextPrintBook: 'Nächstes Buch',
  previousBook: 'Vorheriges E-Book',
  previousPrintBook: 'Vorheriges Buch',
  show: 'E-Book ansehen',

  lentUntil: 'Entliehen bis: ',
  duedate_hint: 'Achtung, bei bestehenden Vormerkungen bzw mehreren Bänden kann Leihfrist abweichen',

  selectTheme: 'Thema wählen:',
  selectSort: 'Sortierung wählen:',
  time: 'Publikationszeitraum:',
  language: 'Sprache',
  studiengang: 'Studiengang',
  semester: 'Semester',
  modul: 'Module',

  invalid_email: 'ungültige E-Mail-Adresse',

  btn_login: 'Login',
  btn_logout: 'Ausloggen',
  btn_cancel: 'Abbruch',
  btn_send: 'Senden',
  btn_submit: 'Senden',
  btn_reset: 'Zurücksetzen',

  set_personal: 'Persönliche Einstellungen',
  set_notifications: 'Benachrichtigungen',
  switch_push: 'Push Benachrichtigungen',
  switch_email: 'E-Mail Benachrichtigungen',

  qrError: 'QR-Code konnte nicht erkannt werden. Versuchen Sie es erneut!',
  qrSuccess: 'QR-Code erkannt',

  siteSettings: 'Einstellungen',
  languagePicker: 'Sprache wechseln',
  searchOnSite: 'Auf der Seite suchen',

  searchBooks: 'E-Books suchen',

  func_search: 'Suche nach E-Books im Regal',
  func_find: 'Suche nach E-Books über den Katalog',
  text_func_search: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',

  placeholder_theme: 'Bitte einen Suchbegriff eingeben',
  placeholder_sortCrit: 'Sortierung wählen',
  placeholder_date: 'Publikationszeitraum wählen',
  placeholder_couter: 'Anzahl der Ergebnisse',
  placeholder_language: 'Sprache der E-Books',
  placeholder_studiengang: 'Studiengang wählen',
  placeholder_semester: 'Semester wählen',
  placeholder_modul: 'Modul wählen',

  author_up: 'Autor aufsteigend',
  author_down: 'Autor absteigend',
  score: 'Relevanz',
  pub_up: 'Jahr aufsteigend',
  pub_down: 'Jahr absteigend',
  title_up: 'Titel aufsteigend',
  title_down: 'Titel absteigend',

  notFound_headline: 'SORRY',
  notFound_text: 'wir konnten die gesuchte Seite leider nicht finden.\n' +
      '            Versuchen Sie es über das Menü. Hier landen Sie auf der ',
  user_login: 'Anmeldung',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail Adresse',
  topics: 'Bitte wählen Sie Bereiche, die Sie interessieren und zu denen Sie Benachrichtigungen erhalten wollen.',
  yourMessage: 'Deine Nachricht für uns ...',

  required_field: '* Pflichtfeld',

  login_failed: 'Benutzername oder Passwort falsch',

  imprint0: 'Verantwortliche für diese Internetseiten gem. § 5 Telemediengesetz (TMG):\n\nProf. Dr. rer. nat. Janett Mohnke\nTH Wildau\nHochschulring 1\n15745 Wildau\n\nTel.: +49 3375 508 291\nMail: janett.mohnke@th-wildau.de\nHaus 14, Raum A228',
  imprintHead1: 'Haftungshinweis',
  imprint1: 'Dieses Impressum gilt nur für jene Dokumente des WWW-Servers der TH Wildau (icampus.th-wildau.de), die auf diese Seite verweisen. Für alle anderen Seiten auf diesem WWW-Server liegt die redaktionelle Verantwortlichkeit bei den jeweiligen Stellen oder Personen, die auf der Seite als Verantwortlicher eingetragen sind bzw. die die Seite erstellt haben.\n\nAuf den Inhalt von Internetseiten anderer Anbieter, auf die die WWW-Seiten der Technischen Hochschule Wildau per Link verweisen, hat die Technische Hochschule Wildau keinen Einfluss. Für den Inhalt dieser Seiten sind ausschließlich die Betreiber der anderen Webseiten verantwortlich. Trotz der Überprüfung der Inhalte im gesetzlich gebotenen Rahmen muss die Technische Hochschule Wildau daher jede Verantwortung für den Inhalt dieser Links bzw. der verlinkten Seite ablehnen.',
  imprintHead2: 'Urheberrecht',
  imprint2: 'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.\n\nSoweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',
  imprintHead3: 'Haftung für Inhalte',
  imprint3: 'Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.\n\nVerpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',
  imprintHead4: 'Haftung für Links',
  imprint4: 'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.\n\nEine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.',

  legal_notice: 'Impressum',
  ln_name: 'Technische Hochschule Wildau',
  ln_contact: 'Hochschulring 1 <br> 15745 Wildau <br><br> Tel: +49 (0) 3375 / 508 - 300 (Telefonzentrale) <br> E-Mail: hochschulkommunikation(at)th-wildau.de',
  ln_represented_by_header: 'Vertreten durch',
  ln_represented_by_text: 'Die Technische Hochschule Wildau ist eine Körperschaft des öffentlichen Rechts. Sie wird nach außen durch die Präsidentin, Prof. Dr. Ulrike Tippe, vertreten.',
  ln_supervisor_header: 'Zuständige Aufsichtsbehörde:',
  ln_supervisor_text: 'Die Hochschule untersteht der Rechtsaufsicht des Ministeriums für Wissenschaft, Forschung und Kultur des Landes Brandenburg.',
  ln_univ_comm_header: 'Redaktionelle Betreuung Website & Social Media',
  ln_univ_comm_text: 'Die von einzelnen Organisationseinheiten der Hochschule betreuten Seiten und Kanäle unterliegen der redaktionellen Verantwortung der jeweiligen Bereiche. Die zuständigen Ansprechpartnerinnen und Ansprechpartner sind auf den entsprechenden Seiten genannt. <br> Sollten keine Kontaktdaten angegeben sein, wenden Sie sich an die zentrale Online-Redaktion:<br> <h3> Zentrum für Hochschulkommunikation </h3> <br> Susanne Baron, Mike Lange <br> website(at)th-wildau.de <br> Hochschulring 1 <br> 15745 Wildau',
  ln_techn_suppport_header: 'Technische Betreuung: ',
  ln_techn_support_text: 'Die technische Betreuung der zentralen Webseiten der Technischen Hochschule Wildau obliegt, wenn nicht anders angegeben, dem Hochschulrechenzentrum.',
  ln_disclaimer_header: 'Haftungshinweis',
  ln_disclaimer_text: 'Dieses Impressum gilt nur für jene Dokumente des WWW-Servers der TH Wildau (www.th-wildau.de), die auf diese Seite verweisen. Für alle anderen Seiten auf diesem WWW-Server liegt die redaktionelle Verantwortlichkeit bei den jeweiligen Stellen oder Personen, die auf der Seite als Verantwortlicher eingetragen sind bzw. die die Seite erstellt haben. <br> Auf den Inhalt von Internetseiten anderer Anbieter, auf die die WWW-Seiten der Technischen Hochschule Wildau per Link verweisen, hat die Technische Hochschule Wildau keinen Einfluss. Für den Inhalt dieser Seiten sind ausschließlich die Betreiber der anderen Webseiten verantwortlich. Trotz der Überprüfung der Inhalte im gesetzlich gebotenen Rahmen muss die Technische Hochschule Wildau daher jede Verantwortung für den Inhalt dieser Links bzw. der verlinkten Seite ablehnen.',
  ln_copyright_header: 'Urheberrecht',
  ln_copyright_text: 'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. <br>  Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.'
}
