<!--
   Login component - to make it possible for the user to log in with a TH Wildau LDAP account from HRZ
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
            <template v-slot:activator="{ on }">
                <v-btn color="secondary-button ml-4" small v-on="on">
                    <v-icon>mdi-login</v-icon>
                    {{ $t('btn_login') }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('user_login') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="12">
                                <v-text-field label="Username*" required v-model="username"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                                <v-text-field label="Password*" type="password" required v-model="password" v-on:keyup.enter="_login"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row>
                      <div v-if="login_failed" class="text-center red--text font-weight-black">
                        {{ $t('login_failed') }}
                      </div>
                    </v-row>
                    <v-row>
                      <small>{{ $t('required_field') }} </small>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="secondary-button" text @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
                    <v-btn class="primary-button" text @click="_login">{{ $t('btn_login') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LoginLogout',
  data: () => ({
    username: '',
    password: '',
    login_failed: false,
    dialog: false
  }),
  methods: {
    ...mapActions(['login', 'logout', 'getRecommendations']),
    /**
     * If you close the log in form reset all variables
     */
    closeDialog () {
      this.dialog = false
      this.login_failed = false
      this.username = ''
      this.password = ''
    },
    /**
     * On login get the recommendations and print them
     * @returns {Promise<void>}
     * @private
     */
    async _login () {
      var loginData = { 'username': this.username, 'password': this.password }

      try {
        await this.login(loginData)
        this.getRecommendations()
      } catch (err) {
        this.login_failed = true
      }
    }
  },
  computed: {
    ...mapGetters(['logStatus'])
  }
}
</script>
