<!--
   Reservation overlay component - make it possible to send a reservation request
   @author Dana Geier
   @version 1.0 / 2021-06-23
-->
<template>
  <v-dialog v-if="logStatus" v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn class="secondary-button mt-5 ml-5" v-on="on">
        <v-icon>mdi-forward</v-icon>
        {{ $t('Reservate') }}
      </v-btn>
    </template>
  <v-card>
    <v-card-title>
      <span v-if="reservationState == 'confirmation'" class="headline">{{ $t('reservation') }}</span>
      <span v-if="reservationState == 'success'" class="headline green--text font-weight-black">{{ $t('reservation_successfull') }}</span>
      <span v-if="reservationState == 'failed'" class="headline red--text font-weight-black">{{ $t('reservation_failed') }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col v-if="reservationState == 'confirmation'" cols="12" sm="6" md="12">
          {{  $t('reservationRequest') }}
          </v-col>
          <v-col v-if="reservationState == 'loading'" class="text-center font-weight-black">
            {{ $t('doing_reservation') }}
          </v-col>
        </v-row>
        <v-row>
          <div v-if="reservationState == 'failed'" class="text-center font-weight-black">
              <v-row v-for="(row, index) in reservationResult.screenMessage" :key="index">
                {{ row }}
              </v-row>
          </div>
          <div v-if="reservationState == 'failed_API'" class="text-center red--text font-weight-black">
            {{ $t('reservation_API_failed') }}
          </div>
          <!-- <div v-if="reservationState == 'success'" class="text-center green--text font-weight-black"> -->
            <div v-if="reservationState == 'success'">
              <v-row v-for="(row, index) in reservationResult.screenMessage" :key="index">
                {{ row }}
              </v-row>
          </div>
        </v-row>
      </v-container>
      <v-row>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="reservationState == 'confirmation'" class="third-button" outlined @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
      <v-btn v-if="reservationState == 'success' || reservationState == 'failed'" class="third-button" outlined @click="closeDialog">OK</v-btn>
      <v-btn v-if="reservationState == 'confirmation'" class="success-button" outlined @click="sendRes">{{ $t('btn_send') }}</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReservationOverlay',
  props: {
    reservationItem: Object
  },
  data () {
    return {
      model: null,
      dialog: false,
      search: null,
      entries: []
      // reservationState: 'confirmation' or 'loading' or 'success'/'failed
      // confirmation: true,
      // doingRequest: false,
      // success: false,
      // failure: false
    }
  },
  computed: {
    ...mapGetters(['logStatus', 'email', 'reservationResult', 'reservationState']),
    items () {
      return this.entries
    }
  }, /*
  watch: { */
  /**
     * Look for the input of the user and find related email addresses in the TH Wildau server
     * @param val
     */
  /*
    search (val) {
      if (val) {
        val = val.replace(/\s/g, '%20')
        var request = 'https://icampus.th-wildau.de/services.php?using_version=1.9&service=AccountInfo&action=users&query=' + val
        // Lazily load input items
        console.log(request)
        fetch(request)
          .then(res => res.json())
          .then(res => {
            if (!res.response.error) {
              this.entries = res.response
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    }
  }, */

  methods: {
    ...mapActions(['placeReservation', 'setReservationState']),
    /**
     * Send a reservation
     */
    async sendRes () {
      this.reservationState = 'doingRequest'
      // console.log('call sendRes')
      // console.log(this.reservationItem)
      await this.placeReservation(this.reservationItem)
      // console.log(this.reservationResult)
      // this.closeDialog()
    },
    /**
     * Close the email dialog only with the button
     */
    closeDialog () {
      this.dialog = false
      this.setReservationState('confirmation')
    },
    /**
     * Create a link to the e-book on the publishers web site
     * @param link the raw link
     * @param linkPda the full link
     */
    link: function (link, linkPda) {
      // console.log(link, linkPda)
      if (link) {
        window.open('https://wilbert.kobv.de/' + link + '&type=ebook_fulltext&ind=&index=internal&plv=2,2', '_blank')
      } else {
        window.open('https://wilbert.kobv.de/' + linkPda, '_blank')
      }
    }
  }
}
</script>
