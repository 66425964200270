<!--
   Contact site
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
    <div class="contact_form">
            <v-container>
                <v-col class="col-1"></v-col>
                <v-col>
                    <v-banner
                    >
                        <h2>{{ $t('contact') }} </h2>
                    </v-banner>
                    <v-form v-model="valid" method="post" action="mailto:christopher.graetz@th-wildau.de">
                    <v-row>
                        <v-col>
                            <v-row>

                            <!-- Input your first name -->
                            <v-col class="col-lg-5 col-md-3">
                                <v-text-field
                                    v-model="firstname"
                                    :rules="nameRules"
                                    :counter="30"
                                    :label="$t('firstName')"
                                    required
                                ></v-text-field>
                            </v-col>

                            <!-- Input your last name -->
                            <v-col class="col-lg-5 col-md-3">
                                <v-text-field
                                    v-model="lastname"
                                    :rules="nameRules"
                                    :counter="30"
                                    :label="$t('lastName')"
                                    required
                                ></v-text-field>
                            </v-col>

                            <!-- Input your email the first time -->
                            <v-col class="col-lg-5 col-md-3">
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    :label="$t('email')"
                                    required
                                ></v-text-field>
                            </v-col>

                            <!-- Input your email a second time -->
                            <v-col class="col-lg-5 col-md-3">
                                <v-text-field
                                    v-model="email2"
                                    :rules="emailRules"
                                    :label="$t('email')"
                                    required
                                ></v-text-field>
                            </v-col>

                            <!-- Select a general topic -->
                            <v-col class="col-lg-5 col-md-3">
                                <v-select
                                    v-model="select"
                                    :items="items"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('topics')"
                                    required
                                ></v-select>
                            </v-col>
                            </v-row>
                        </v-col>

                        <!-- Show a map with the help center -->
                        <v-col>
                            <iframe
                                style="width: 100%;height: 400px;border:0;"
                                src="https://icampus.th-wildau.de/map/?q=icampus&floor=0&zoom=20&controls[results]=hide&building=13"
                                name="icampusmap"
                                class="icampusmap">Alternativtext
                            </iframe>
                        </v-col>

                    </v-row>

                    <!-- Input a message -->
                    <v-row>
                        <v-textarea
                                class=""
                                v-model="text"
                                :label="$t('yourMessage')"
                                rows="1"
                                prepend-icon="mdi-message-reply-text"
                                clearable
                                auto-grow
                                outlined
                                background-color="rgba(20,50,255,0.05)"
                                counter="500"
                        ></v-textarea>
                    </v-row>

                    <!-- Now accept the data commitment and send or reset the form -->
                    <v-row>
                        <v-checkbox
                                v-model="checkboxChecked"
                                color="primary"
                                hide-details
                                label="primary"
                                required
                        >
                            <template v-slot:label>
                                I agree to the {{ $t('terms') }} and {{ $t('privacy') }}
                            </template>
                        </v-checkbox>
                    </v-row>
                    <br>
                    <v-row>
                        <v-btn
                                class="third-button mr-4"
                                @click="this.reset"
                        >
                           {{ $t('btn_reset') }}
                        </v-btn>

                        <v-btn
                                class="secondary-button"
                                :disabled="!checkboxChecked"
                                @click="sendData"
                        >
                            {{ $t('btn_submit') }}
                        </v-btn>
                    </v-row>
                </v-form>
            </v-col>
            <v-col class="col-1"></v-col>
        </v-container>
    </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: 'Contact',
  data: () => ({
    checkboxChecked: false,
    valid: false,
    firstname: '',
    lastname: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 30 || 'Name must be less than 10 characters'
    ],
    email: '',
    email2: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    select: null,
    items: [
      'Allgemein',
      'Verbesserungsvorschlag',
      'Hinweis',
      'Nachfrage'
    ],
    text: ''
  }),
  methods: {
    /**
     * Reset the form
     */
    reset () {
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.email2 = ''
      this.text = ''
      this.select = null
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    /**
     * Send the user given user data via an email in a formatted way
     */
    sendData () {
      if (this.email !== this.email2) {
        alert('Check your emails')
      } else {
        let data = {
          firstName: this.firstname,
          lastName: this.lastname,
          email: this.email,
          category: this.select,
          message: '\n' + this.text
        }
        // axios.post('http://127.0.0.1:3000/produtos', data)
        //   .then(function (response) {
        //     console.log(response)
        //   })
        //   .catch(function (error) {
        //     console.log(error)
        //   })

        // convert the given data into a better readable format for the email
        let dataString = JSON.stringify(data).replace(/\\n/g, '\r\n').replace(/{/g, '').replace(/}/g, '').replace(/:/g, ':\t\t').replace(/",/g, ',\n').replace(/"/g, ' ')
        window.open('mailto:christopher.graetz@th-wildau.de?subject=' + this.select + '&body=' + encodeURIComponent(dataString))
        if (window && window.open && !window.closed) {
          window.close()
        }
      }
    }
  }
}
</script>
