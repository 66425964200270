/**
 * Initialise the multi language support and add it to the web-app
 * @author Christopher Grätz
 * @version 1.0 / 2020-11-27
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../translations'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
})
