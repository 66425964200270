<!--
   Language picker component - to switch between the actual implemented languages
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
<!-- https://demos.creative-tim.com/vuetify-material-dashboard/documentation/component-docs/dropdown.html -->
  <v-menu
    bottom
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(lang, i) in $i18n.availableLocales"
        :key="i"
        @click="changeLanguage(lang)"
      >
        <v-list-item-icon>
          <flag
            :iso="$i18n.t('flagCode', lang)"
            :squared=false
          />
        </v-list-item-icon>
        <v-list-item-title>{{ $t('languages.' + lang, lang) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguagePicker',
  methods: {
    changeLanguage (lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('language/setLanguage', lang)
    }
  }
}
</script>
