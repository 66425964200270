import { BACKEND_URL } from '@/store/index.js'

let axios = require('axios')
let iconv = require('iconv-lite')

/**
 * Retrieves the current semester of student
 * @param {int} seminarGruppe of enrollment of student
 */
/*
function getSemester (seminarGruppe, numberofSemesters) {
  console.log('calling getSemester')
  let currentSemester
  let date = new Date()
  let currentMonth = date.getMonth() + 1
  let currentYear = date.getFullYear().toString().substr(-2)
  if (currentMonth >= 9 || currentMonth < 3) {
    if (currentMonth < 3) currentYear--
    switch (parseInt(currentYear) - parseInt(seminarGruppe)) {
      case 0:
        currentSemester = 1
        break
      case 1:
        currentSemester = 3
        break
      case 2:
        currentSemester = 5
        break
      case 3:
        currentSemester = 7
        if (currentSemester > numberofSemesters) currentSemester--
        break
      case 4:
        currentSemester = 9
        if (currentSemester > numberofSemesters) currentSemester--
        break
    }
  } else {
    switch (parseInt(currentYear) - parseInt(seminarGruppe)) {
      case 1:
        currentSemester = 2
        break
      case 2:
        currentSemester = 4
        break
      case 3:
        currentSemester = 6
        if (currentSemester > numberofSemesters) currentSemester--
        break
      case 4:
        currentSemester = 8
        if (currentSemester > numberofSemesters) currentSemester--
        break
      case 5:
        currentSemester = 10
        if (currentSemester > numberofSemesters) currentSemester--
        break
    }
  }
  console.log(currentSemester)
  return currentSemester
}
*/

/**
 * Generates recommendations for student
 * Retrieves book information from Wilbert
 * Retrieves book cover urls from server.
 * @param {int} year of enrollment of student
 */
async function generateRecommendations (studiengang, seminargruppe, token, updateUI) {
  let recRequest = 'https://icampus.th-wildau.de/services.php?using_version=1.9&service=ModuleGuide&action=literature&seminargroup=' + studiengang + '-' + seminargruppe + '&token=' + token
  let recBooks = []
  let promises = []
  let _promises = []
  let __promises = []
  // console.log(recRequest)
  await axios.get(recRequest).then(async function (response) {
    let responseData = response.data
    // console.log(responseData)
    // const currentSemester = getSemester(seminargruppe, responseData.response.length)
    // const semesterData = responseData.response[currentSemester - 1]
    const semesterData = responseData.response[0]
    // console.log(semesterData)
    const modules = semesterData.modules
    // if (modules)
    // console.log(modules)
    modules.forEach(async function (module) {
      const literature = module.literature
      literature.forEach(async function (book) {
        if (book.type === 'custom' || book.type === 'external') {
          let citationTitle = iconv.decode(book.citation, 'UTF-8')
          // console.log('vor replace: ')
          // console.log(citationTitle)
          citationTitle = citationTitle.replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
          // console.log('nach replace: ')
          // console.log(citationTitle)
          let regex = /[0-9]{4}.*?\s(.*?)\.?$/
          try {
            citationTitle = regex.exec(citationTitle)[1]
          } catch (error) {
          }
          citationTitle = citationTitle.split('.')[0]
          citationTitle = citationTitle.split(':')[0]
          citationTitle = citationTitle.replace(/\s/g, '+')
          let promise = new Promise(async function (resolve, reject) {
            let result = await getBookData(citationTitle)
            recBooks.push.apply(recBooks, await result)
            return resolve(result)
          })
          promises.push(promise)
          // console.log(citationTitle)
        } else if (book.type === 'library') {
          //  console.log(book.catkey)
          let _promise = new Promise(async function (resolve, reject) {
            let result = await getBookData(book.catkey)
            recBooks.push.apply(recBooks, await result)
            return resolve(result)
          })
          _promises.push(_promise)
        } else {
          let bookTitle = iconv.decode(book.title, 'UTF-8')
          bookTitle = bookTitle.replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
          bookTitle = bookTitle.replace(/\s/g, '+')
          // console.log(bookTitle)
          let __promise = new Promise(async function (resolve, reject) {
            let result = await getBookData(bookTitle)
            recBooks.push.apply(recBooks, await result)
            return resolve(result)
          })
          __promises.push(__promise)
        }
      })
    })
    // console.log(modules)
    Promise.all(promises).then(function () {
      updateUI(recBooks)
      // console.log('first')
    })
    Promise.all(_promises).then(function () {
      updateUI(recBooks)
      // console.log('second')
    })
    Promise.all(__promises).then(function () {
      updateUI(recBooks)
      // console.log('third')
    })
    return true
  })
}

/**
 * Generates all recommendations for student
 * Retrieves book information from Wilbert
 * Retrieves book cover urls from server.
 * @param {int} year of enrollment of student
 */
async function generateForSem (studiengang, seminargruppe, token, requestedSemester, updateUI) {
  let recRequest = 'https://icampus.th-wildau.de/services.php?using_version=1.9&service=ModuleGuide&action=literature&seminargroup=' + studiengang + '-' + seminargruppe + '&token=' + token
  let promises = []
  let _promises = []
  let __promises = []
  try {
    await axios.get(recRequest).then(async function (response) {
      let responseData = response.data
      const semesterData = responseData.response[requestedSemester - 1]
      const modules = semesterData.modules
      // console.log(modules)
      modules.forEach(async function (module) {
        module['title'] = iconv.decode(module['title'], 'UTF-8')
        module['title'] = module['title'].replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
        module['recBooks'] = []
        const literature = module.literature
        literature.forEach(async function (book) {
          if (book.type === 'custom' || book.title === 'external') {
            // console.log(book.type)
            // console.log(book)
            let citationTitle = iconv.decode(book.citation, 'UTF-8')
            citationTitle = citationTitle.replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
            // console.log(citationTitle)
            let regex = /[0-9]{4}.*?\s(.*?)\.?$/
            try {
              citationTitle = regex.exec(citationTitle)[1]
            } catch (error) {
            }
            citationTitle = citationTitle.split('.')[0]
            citationTitle = citationTitle.split(':')[0]
            citationTitle = citationTitle.replace(/\s/g, '+')
            // console.log(citationTitle)
            let promise = new Promise(async function (resolve, reject) {
              let result = await getBookData(citationTitle)
              module['recBooks'].push.apply(module['recBooks'], await result)
              return resolve(result)
            })
            promises.push(promise)
            // console.log(citationTitle)
          } else if (book.type === 'library') {
            //  console.log(book.catkey)
            let _promise = new Promise(async function (resolve, reject) {
              let result = await getBookData(book.catkey)
              module['recBooks'].push.apply(module['recBooks'], await result)
              return resolve(result)
            })
            _promises.push(_promise)
          } else {
            let bookTitle = iconv.decode(book.title, 'UTF-8')
            bookTitle = bookTitle.replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
            bookTitle = bookTitle.replace(/\s/g, '+')
            // console.log(bookTitle)
            let __promise = new Promise(async function (resolve, reject) {
              let result = await getBookData(bookTitle)
              module['recBooks'].push.apply(module['recBooks'], await result)
              return resolve(result)
            })
            __promises.push(__promise)
          }
        })
      })
      Promise.all(promises).then(function () {
        updateUI(modules)
        // console.log('first')
      })
      Promise.all(_promises).then(function () {
        updateUI(modules)
        // console.log('second')
      })
      Promise.all(__promises).then(function () {
        updateUI(modules)
        // console.log('third')
      })
      return true
    })
  } catch (err) {
    throw new Error()
  }
}

async function getNumberOfSemesters (studiengang, seminargruppe, token, updateUI) {
  let recRequest = 'https://icampus.th-wildau.de/services.php?using_version=1.9&service=ModuleGuide&action=literature&seminargroup=' + studiengang + '-' + seminargruppe + '&token=' + token
  try {
    await axios.get(recRequest).then(async function (response) {
      let responseData = response.data
      // get the length of list in reponse
      let numberofSemesters = responseData.response.length
      updateUI(numberofSemesters)
    })
  } catch (err) {
    console.log(err)
  }
}

async function getModules (desiredSemester, studiengang, seminargruppe, token, updateUI) {
  let recRequest = 'https://icampus.th-wildau.de/services.php?using_version=1.9&service=ModuleGuide&action=literature&seminargroup=' + studiengang + '-' + seminargruppe + '&token=' + token
  try {
    await axios.get(recRequest).then(async function (response) {
      let responseData = response.data
      // extract the modules for the response
      let modules = responseData.response[desiredSemester - 1].modules
      // Re-code the titles in UTF-8
      modules.forEach(async function (module) {
        module['title'] = iconv.decode(module['title'], 'UTF-8')
        module['title'] = module['title'].replace(/[^a-z0-9 üäö:,.?!-]/ig, '')
      })
      updateUI(modules)
    })
  } catch (err) {
    console.log(err)
  }
}

async function getBookData (searchTerm) {
  let url = BACKEND_URL + '/searchTerm/' + searchTerm
  let allBooks = []
  await axios.get(url, { timeout: 12000 }).then(function (response) {
    allBooks = response.data
    return true
  }).catch(function () {
    return false
  })
  return allBooks
}

export default { generateRecommendations, generateForSem, getNumberOfSemesters, getModules }
