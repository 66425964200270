<!--
   QR-code scanner component - scan a qr code with your devices camera
   @author Christopher Grätz, Omar Attia
   @version 1.0 / 2020-11-25
-->
<template>
  <div>
    <qrcode-stream @decode="onDecode" @init="onInit">
      <div v-if="loading">
        <div class="text-center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        </div>
      </div>

       <div v-if="validationPending">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <div v-if="validationFailure">
        <v-alert type="error" color="rgba(255, 255, 255, 0.5)">
        {{ $t('qrError') }}
        </v-alert>
      </div>

      <div v-if="validationSuccess">
        <v-alert type="success">
        {{ $t('qrSuccess') }}
        </v-alert>
      </div>

    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'QrCodeScanner',
  components: { QrcodeStream },
  data () {
    return {
      loading: false,
      result: '',
      error: '',
      camera: '',
      isValid: undefined
    }
  },
  computed: {
    ...mapGetters(['hits']),
    validationPending () {
      return this.isValid === undefined &&
        this.camera === 'off'
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
    ...mapActions(['fetchBooks', 'fetchLentBooks', 'setShelfID', 'setQrPosition']),

    /**
     * Decode the qr-code and commit its data to the store function to get the related e-books
     */
    async onDecode (result) {
      // console.log('QR-Code erkannt: ', result)
      this.setQrPosition(result)
      this.result = result // QR-Code-content
      this.turnCameraOff()
      // commit-string: the shelf id and all other possible preferences
      var clientData = { 'qrCode': this.result, 'hits': this.hits }

      // now get the e-books from the store
      // if (this.fetchBooks_new(clientData) && this.fetchLentBooks(clientData)) {
      if (this.fetchBooks(clientData) && this.fetchLentBooks(clientData)) {
        this.isValid = true
        await this.timeout(2000)
        // this.turnCameraOn()
      } else {
        this.isValid = false
        await this.timeout(2000)
        // this.turnCameraOn()
      }
      await this.timeout(3000)
      this.turnCameraOff()
    },

    /**
     * Start the scan process of the qr-code
     * @param promise
     * @returns {Promise<void>}
     */
    async onInit (promise) {
      this.loading = true
      // console.log('HERE')
      // this.turnCameraOff()
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
      } finally {
        this.loading = false
      }
    },
    resetValidationState () {
      this.isValid = undefined
    },
    /**
     * Turn the camera on
     * Possible values are 'frot', 'rear', 'auto'
     */
    turnCameraOn () {
      this.camera = 'auto'
    },
    /**
     * Turn the camera off
     */
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  },
  watch: {
    camera () {
      if (this.camera === 'off') {
        this.turnCameraOff()
      }
    }
  }
}
</script>
