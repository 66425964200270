<!--
   EMail overlay component - make it possible to send a email recommendation
   @author Christopher Grätz
   @version 1.0 / 2020-11-25
-->
<template>
  <v-dialog v-if="logStatus" v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn class="secondary-button mt-5 ml-5" v-on="on">
        <v-icon>mdi-forward</v-icon>
        {{ $t('Recommend') }}
      </v-btn>
    </template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('friend') }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <!-- Fill in an email-->
            <v-text-field label="E-Mail" type="text" required v-model="model" v-on:keyup.enter="sendRec"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="mailMsg === 'noValidEmail'" class="text-center red--text font-weight-black">{{ $t('invalid_email') }}</v-row>
      </v-container>
      <v-row>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="third-button" outlined @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
      <v-btn class="success-button" outlined @click="sendRec">{{ $t('btn_send') }}</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EMailOverlay',
  props: {
    sendBook: Object
  },
  data () {
    return {
      model: null,
      dialog: false,
      search: null,
      entries: [],
      mailMsg: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  computed: {
    ...mapGetters(['logStatus', 'email']),
    items () {
      return this.entries
    }
  },
  watch: {
    /**
     * Look for the input of the user and find related email addresses in the TH Wildau server
     * @param val
     */
    search (val) {
      if (val) {
        val = val.replace(/\s/g, '%20')
        var request = 'https://icampus.th-wildau.de/services.php?using_version=1.4&service=AccountInfo&action=users&query=' + val
        // Lazily load input items
        console.log(request)
        fetch(request)
          .then(res => res.json())
          .then(res => {
            if (!res.response.error) {
              this.entries = res.response
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    }
  },
  methods: {
    ...mapActions(['sendRecommendation']),
    /**
     * Send a recommendation to the user via the vue store and the backend, where the email will be generated
     */
    sendRec () {
      console.log('call sendRec')
      if (this.reg.test(this.model)) {
        var queryString = Object.keys(this.sendBook).map(key => encodeURI(key) + '=' + encodeURI(this.sendBook[key])).join('&')
        var requestOptions = {
          'from': this.email,
          'to': this.model,
          'bookLink': queryString
        }
        console.log(requestOptions)
        this.sendRecommendation(requestOptions)
        this.closeDialog()
      } else {
        this.mailMsg = 'noValidEmail'
      }
    },
    /**
     * Close the email dialog only with the button
     */
    closeDialog () {
      this.dialog = false
    },
    /**
     * Create a link to the e-book on the publishers web site
     * @param link the raw link
     * @param linkPda the full link
     */
    link: function (link, linkPda) {
      // console.log(link, linkPda)
      if (link) {
        window.open('https://wilbert.kobv.de/' + link + '&type=ebook_fulltext&ind=&index=internal&plv=2,2', '_blank')
      } else {
        window.open('https://wilbert.kobv.de/' + linkPda, '_blank')
      }
    }
  }
}
</script>
