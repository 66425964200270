/**
 * TH Wildau corporate style for buttons and text
 */
export default {
  primary: {
    base: '#0069b4',
    lighten1: '#eff9ff'
  },
  // the gradient in Header is set manually with rgb
  primarygradient: {
    lighten1: '#0d64ac',
    darken1: '#042f7e'
  },
  secondary: {
    base: '#fc9000'
  },
  secondarygradient: {
    lighten1: '#f49e30',
    darken1: '#ed7724'
  },
  successgradient: {
    lighten1: '#40c600',
    darken1: '#409900'
  },
  error: '#d80976',
  errorgradient: {
    lighten1: '#d80976',
    darken1: '#d84076'
  },
  texting: {
    base: '#666666',
    darken1: '#2f3235',
    lighten1: '#bababa',
    lighten2: '#ffffff'
  },
  accent: '#82B1FF',
  info: '#2196F3',
  warning: '#FFC107'
}
